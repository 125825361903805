import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import MyDocument from "./MyDocument";

import { useLocation, useParams } from "react-router-dom";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [invoiceData2, setInvoiceData2] = React.useState([]);
  const [state, setState] = React.useState({
    buyer: true,
    department: false,
    team: false,
    factory: false,
    product_type: true,
    quantity: true,
    commission_value: false,
    quantity_percentage: false,
    rdl_value: false,
    factory_value: false,
  });

  React.useEffect(() => {
    document.title = "Sales Base on ETD";
  }, []);

  const location = useLocation();
  let { id } = useParams();
  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getData = localStorage.getItem("etd_datewise_sales")
    ? JSON.parse(localStorage.getItem("etd_datewise_sales"))
    : null;

  const getState = localStorage.getItem("etd_state")
    ? JSON.parse(localStorage.getItem("etd_state"))
    : null;

  console.log("getState", getState);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={getData ? getData : null}
        state={getState}
        getInfo={getInfo}
        month={id}
      />
    </PDFViewer>
  );
};

export default Invoice;
