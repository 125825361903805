import { combineReducers } from "redux";
import uiReducer from "./uiReducers";
import MenuReducers from "./menuReducers";
//admin
import userReducers from "./modules/AdminPanel/userReducers";
import userRolesReducers from "./modules/userRolesReducers";
import userLevelPrivilegesReducer from "./modules/userLevelPrivilegesReducer";
import userLevelsReducer from "./modules/userLevelsReducer";
import yearReducers from "./modules/yearReducers";

//settings
import bankReducers from "./modules/banksReducers";
import supplierReducers from "./modules/suppliersReducers";
import buyerReducers from "./modules/buyersReducers";
import buyerBrandsReducers from "./modules/buyerBrandsReducers";
import buyerDeptReducers from "./modules/buyerDeptReducers";
import colorReducers from "./modules/colorReducers";
import overseaReducers from "./modules/overseaReducers";
import sizeSetsReducers from "./modules/sizeSetsReducers";
import productReducers from "./modules/productReducers";
import productTypeReducers from "./modules/productTypeReducers";
import seasonReducrs from "./modules/seasonReducrs";
import bankAccountsReducers from "./modules/bankAccountsReducers";
import buyerTermsReducers from "./modules/buyerTermsReducers";
import companiesReducers from "./modules/companiesReducers";
import couriersReducers from "./modules/couriersReducers";
import exFactoriesReducers from "./modules/exFactoriesReducers";
import fabricsReducers from "./modules/fabricsReducers";
import fabricTypesReducers from "./modules/fabricTypesReducers";
import portsReducers from "./modules/portsReducers";
import userAccessesReducers from "./modules/userAccessReducers";
import fobTypesReducers from "./modules/fobTypesReducers";
import countriesReducers from "./modules/countriesReducers";
import currenciesReducers from "./modules/currenciesReducers";
import LoginReducers from "./auth/authReducers";
import permissionsReducers from "./auth/permissionsReducers";
import OrdersReducers from "./modules/OrdersReducers";
import teamsReducers from "./modules/teamsReducers";
import paymentTermsReducers from "./modules/paymentTermsReducers";
import orderDetailsReducers from "./modules/orderDetailsReducers";
import shipmentDetailsReducers from "./modules/shipmentDetailsReducers";
import factoryOrdersReducers from "./modules/factoryOrdersReducers";
import factoryOrderDetailsReducers from "./modules/factoryOrderDetailsReducers";
import factoryShipmentDetailsReducers from "./modules/factoryShipmentDetailsReducers";
import tnaActionsReducers from "./modules/tnaActionsReducers";
import tnaTemplatesReducers from "./modules/tnaTemplatesReducers";
import tnaTemplateActionsReducers from "./modules/tnaTemplateActionsReducers";
import tnaHeadersReducers from "./modules/tnaPlanHeaderReducers";
import tnaPlanDetailsReducers from "./modules/tnaPlanDetailsReducers";
import exFactoryInfoReducers from "./modules/exFactoryInfoReducers";
import salesContractActionsReducers from "./modules/salesContractActionsReducers";
import salesContractReducers from "./modules/salesContractReducers";
import commissionsReducers from "./modules/commissionsReducers";
import commissionDetailsReducers from "./modules/commissionDetailsReducers";
import lcHeaderReducers from "./modules/lcHeaderReducers";
import lcHeaderDetailsReducers from "./modules/lcHeaderDetailsReducers";
import exfactoryInfoDetailsReducers from "./modules/exfactoryInfoDetailsReducers";
import SCAmendReducers from "./modules/salesContractAmendReducers";
import approvalPanelsReducers from "./modules/approvalPanelsReducers";
import approvalPanelMembersReducers from "./modules/approvalPanelMembersReducers";
import discountsReducer from "./modules/discountsReducer";
import discountDetailsReducer from "./modules/discountDetailsReducer";
import factoryInvoiceReducer from "./modules/factoryInvoiceReducer";
import factoryInvoiceDetailReducer from "./modules/factoryInvoiceDetailReducer";
import RDLInvoiceReducer from "./modules/RDLInvoiceReducer";
import RDLInvoiceDetailReducer from "./modules/RDLInvoiceDetailReducer";
import TTAdvanceReducer from "./modules/TTAdvanceReducer";
import TTAdvanceDetailReducer from "./modules/TTAdvanceDetailReducer";
import approvalFormSetupReducers from "./modules/approvalFormSetupReducers";
import commissionInvoiceReducer from "./modules/commissionInvoiceReducer";
import proceedRealizationReducer from "./modules/proceedRealizationReducer";
import proceedRealizationDetailsReducer from "./modules/proceedRealizationDetailsReducer";
import freightTermsReducer from "./modules/freightTermsReducer";

const rootReducer = combineReducers({
  ui: uiReducer,
  menuList: MenuReducers,
  userList: userReducers,
  loginInfoList: LoginReducers,
  permissionList: permissionsReducers,
  userRoleList: userRolesReducers,
  userAccessList: userAccessesReducers,
  bankList: bankReducers,
  supplierList: supplierReducers,
  buyerList: buyerReducers,
  overseasList: overseaReducers,
  colorList: colorReducers,
  buyerBrandList: buyerBrandsReducers,
  buyerDepartmentList: buyerDeptReducers,
  sizeSetList: sizeSetsReducers,
  seasonList: seasonReducrs,
  productTypeList: productTypeReducers,
  productList: productReducers,
  bankAccountList: bankAccountsReducers,
  buyerTermList: buyerTermsReducers,
  companyList: companiesReducers,
  courierList: couriersReducers,
  fabricList: fabricsReducers,
  fabricTypeList: fabricTypesReducers,
  portList: portsReducers,
  exFactoryList: exFactoriesReducers,
  countryList: countriesReducers,
  currencyList: currenciesReducers,
  fobTypesList: fobTypesReducers,
  orderList: OrdersReducers,
  teamList: teamsReducers,
  paymentTermList: paymentTermsReducers,
  orderDetailList: orderDetailsReducers,
  shipmentDetailList: shipmentDetailsReducers,
  factoryOrderList: factoryOrdersReducers,
  factoryOrderDetailList: factoryOrderDetailsReducers,
  factoryShipmentDetailList: factoryShipmentDetailsReducers,
  tnaActionList: tnaActionsReducers,
  tnaTemplateList: tnaTemplatesReducers,
  tnaTemplateActionList: tnaTemplateActionsReducers,
  tnaHeaderList: tnaHeadersReducers,
  tnaPlanDetailList: tnaPlanDetailsReducers,
  exFactoryInfo: exFactoryInfoReducers,
  exFactoryInfoDetail: exfactoryInfoDetailsReducers,
  salesContractList: salesContractReducers,
  scAmendList: SCAmendReducers,
  salesContractActionsList: salesContractActionsReducers,
  commissionsList: commissionsReducers,
  commissionDetailList: commissionDetailsReducers,
  lcHeaderList: lcHeaderReducers,
  lcHeaderDetailList: lcHeaderDetailsReducers,
  approvalPanelList: approvalPanelsReducers,
  approvalPanelMemberList: approvalPanelMembersReducers,
  discountList: discountsReducer,
  discountDetailList: discountDetailsReducer,
  factoryInvoiceList: factoryInvoiceReducer,
  factoryInvoiceDetailList: factoryInvoiceDetailReducer,
  rdlInvoiceList: RDLInvoiceReducer,
  rdlInvoiceDetailList: RDLInvoiceDetailReducer,
  ttAdvanceList: TTAdvanceReducer,
  ttAdvanceDetailList: TTAdvanceDetailReducer,
  approvalFormSetupList: approvalFormSetupReducers,
  commissionInvoiceList: commissionInvoiceReducer,
  proceedRealizationList: proceedRealizationReducer,
  proceedRealizationDetailList: proceedRealizationDetailsReducer,
  freightTermList: freightTermsReducer,
  userLevelList: userLevelsReducer,
  userLevelPrivilegeList: userLevelPrivilegesReducer,
  yearList: yearReducers,
});

export default rootReducer;
