import {
  ADD_YEARS,
  ADD_YEARS_ERROR,
  ADD_YEARS_OK,
  BEGIN_EDIT_YEARS,
  BEGIN_YEARS_DOWNLOAD,
  RETRIEVE_YEARS_BULK_DELETE,
  RETRIEVE_YEARS_DELETE,
  RETRIEVE_YEARS_EDIT,
  YEARS_BULK_DELETED_ERROR,
  YEARS_BULK_DELETED_OK,
  YEARS_DELETED_ERROR,
  YEARS_DELETED_OK,
  YEARS_DOWNLOAD_ERROR,
  YEARS_DOWNLOAD_OK,
  YEARS_EDITED_ERROR,
  YEARS_EDITED_OK,
} from "../../constants/modules/yearsConstants";

const initialState = {
  years: [],
  error: false,
  loading: false,
  deleteYear: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_YEARS_DOWNLOAD:
    case RETRIEVE_YEARS_BULK_DELETE:
    case ADD_YEARS:
    case BEGIN_EDIT_YEARS:
      return {
        ...state,
        loading: action.payload,
        year: action.year,
      };

    case ADD_YEARS_OK:
    case YEARS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_YEARS_ERROR:
    case YEARS_DOWNLOAD_ERROR:
    case YEARS_BULK_DELETED_ERROR:
    case YEARS_DELETED_ERROR:
    case YEARS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case YEARS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        years: action.payload,
      };

    case RETRIEVE_YEARS_DELETE:
      return {
        ...state,
        deleteYear: action.payload,
      };

    case YEARS_DELETED_OK:
      return {
        ...state,
        years: state.years.data.filter((year) => year.id !== state.deleteYear),
        deleteYear: null,
      };

    case RETRIEVE_YEARS_EDIT:
      return {
        ...state,
        editYear: action.payload,
      };

    case YEARS_EDITED_OK:
      return {
        ...state,
        editYear: null,
        years: state.years.data.map((year) =>
          year.id === action.payload.id ? (year = action.payload) : year
        ),
      };

    default:
      return state;
  }
}
