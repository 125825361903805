import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";


const fakeData =[
 {
  buyer_name: "DMR",
  data: [
    {
      dept: "Girls",
      order: "1000",
      ex_factory: "2000",
      qty_balance: "3000",
      quantity_fulfill: "5.00",
      sales: "1000",
      export: "2000",
      rev_balance: "3000",
      rev_fulfill: "10.00"
    },
    {
      dept: "Boys",
      order: "1000",
      ex_factory: "2000",
      qty_balance: "3000",
      quantity_fulfill: "5.00",
      sales: "1000",
      export: "2000",
      rev_balance: "3000",
      rev_fulfill: "10.00"
    },
  ]
},
{
  buyer_name: "COTTON ON",
  data: [
    {
      dept: "Girls",
      order: "1000",
      ex_factory: "2000",
      qty_balance: "3000",
      quantity_fulfill: "5.00",
      sales: "1000",
      export: "2000",
      rev_balance: "3000",
      rev_fulfill: "10.00"
    },
    {
      dept: "Boys",
      order: "1000",
      ex_factory: "2000",
      qty_balance: "3000",
      quantity_fulfill: "5.00",
      sales: "1000",
      export: "2000",
      rev_balance: "3000",
      rev_fulfill: "10.00"
    },
    {
      dept: "Man",
      order: "1000",
      ex_factory: "2000",
      qty_balance: "3000",
      quantity_fulfill: "5.00",
      sales: "1000",
      export: "2000",
      rev_balance: "3000",
      rev_fulfill: "10.00"
    },
  ]
}
]



// Create styles
const Quixote = ({ invoiceData, getInfo, getSummaryInfo }) => {
  const reportHeaderData =
    invoiceData && invoiceData.length ? invoiceData?.[0]?.["0"] : {};

  const tableBodyData = invoiceData && invoiceData.length ? invoiceData?.[0] : {};
  
  const currencyName = invoiceData && invoiceData.length ? invoiceData[1] : {};

  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : [];



  let hash = Object.create(null);

  let result =
    tableBodyDataRefine && tableBodyDataRefine.length !== 0
      ? tableBodyDataRefine.reduce(function (p, c) {
          if (!hash[c.buyer_name]) {
            hash[c.buyer_name] = { buyer_name: c.buyer_name, data: [] };
            p.push(hash[c.buyer_name]);
          }
          hash[c.buyer_name].data.push({
            department: c.department ? c.department : "",
            order_qty: c.order_qty ? c.order_qty : 0,
            order_value: c.order_value ? c.order_value : "0.00",
            order_rate: c.order_rate ? c.order_rate : "0.00",
            exfac_qty: c.exfac_qty ? c.exfac_qty : 0,
            qty_fullfil: c.qty_fullfil ? c.qty_fullfil : 0,
            ship_qty: c.ship_qty ? c.ship_qty : 0,
            ship_value: c.ship_value ? c.ship_value : "0.00",
            ship_rate: c.ship_rate ? c.ship_rate : "0.00",
            value_fullfil: c.value_fullfil ? c.value_fullfil : "0.00",
          });

          return p;
        }, [])
      : [];


  let grandOrderQty = 0;
  let grandExFactQty = 0;
  let grandQtyBalValue = 0;
  let grandSalesValue = 0;
  let grandExportValue = 0;
  let grandRevBalValue = 0;

  
  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  let count = 0;

  const TableData = ({ item, index, customHeight }) => {
    return (
      <View
        wrap={false}
        break={count > 6}
        key={index}
        style={{
          height: customHeight,
          flexDirection: "row",
          width: "100%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.department}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.order_qty ? item?.order_qty : 0}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.exfac_qty ? item?.exfac_qty : 0}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : "$"}{" "}
          {(Number(item?.order_qty) - Number(item?.exfac_qty)).toFixed(2) ?? "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.qty_fullfil ? item?.qty_fullfil : "0"} %
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : "$"}{" "}
          {item?.order_value ? Number(item?.order_value).toFixed(2) : "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : "$"}{" "}
          {item?.ship_value ? Number(item?.ship_value).toFixed(2) : "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : "$"}{" "}
         {(Number(item?.order_value) - Number(item?.ship_value)).toFixed(2) ?? "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.11%",
            textAlign: "center",
          }}
        >
          {item?.value_fullfil ? Number(item?.value_fullfil).toFixed(2) : "0.00"} %
        </Text>
      </View>
    );
  };

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Image
            style={{
              width: "885px",
              height: "75px",
              textAlign: "center",
              margin: "auto",
            }}
            src={logo}
          />
          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "Times",
                backgroundColor: "rgb(56, 56, 141)",
                width: "200px",
                color: "white",
                padding: "6px",
              }}
            >
              RDL SALES VS EXPORT
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-60px",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: "9px" }}>
              Print Date :
              {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
            </Text>
            <Text style={{ fontSize: "9px" }}>
              Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
            </Text>
          </View>
        </View>

        {/* Header Info */}
        <View
          style={{
            height: "40px",
            marginTop: "20px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <Text
              style={{ fontSize: "11px", fontFamily: "Times", fontWeight: 900 }}
            >
              Period
            </Text>
            <Text style={{ fontSize: "10px", paddingLeft: 10 }}>
              : {getSummaryInfo ? new Date(getSummaryInfo?.["from_date"]).toLocaleDateString(["es-CL", "id"])  : null}{" "}
              To
              {" "}{getSummaryInfo ? new Date(getSummaryInfo?.["to_date"]).toLocaleDateString(["es-CL", "id"]) : null}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              paddingBottom: "5px",
            }}
          >
            <Text
              style={{ fontSize: "11px", fontFamily: "Times", fontWeight: 900 }}
            >
              Buyer Name
            </Text>
            <Text style={{ fontSize: "10px", paddingLeft: 10 }}>
              : {getSummaryInfo ? getSummaryInfo?.["buyer_name"] : null}
            </Text>
          </View>
          
        </View>
        {/* TABLE PART */}
        <View >
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            wrap={false}
            fixed
          >
            <Text
              style={{
                width: "10%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                paddingTop: 14,
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              BUYERS
            </Text>
            <Text
              style={{
                width: "10%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                paddingTop: 14,
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              DEPT
            </Text>
            <View style={{ width: "30%"}}>
            <View style={{ width: "100%", borderBottom: "1px solid black"}}>
            <Text
              style={{
                padding: "4px",
                // width: "10%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              QUANTITIES              
            </Text>
            </View>
            <View style={{flexDirection: "row", width: "100%"}}>
            
            <Text
              style={{
                padding: "4px",
                width: "33.33%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              ORDER
            </Text>
            <Text
              style={{
                padding: "4px",
                width: "33.33%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                color: "red",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              EX-FACTORY
            </Text>
            <Text
              style={{
                padding: "4px",
                width: "33.33%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              BALANCE
            </Text>
            </View>
            </View>
            <Text
              style={{
                paddingTop: 9,
                fontSize: "9px",
                width: "10%",
                color: "red",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
             QUANTITY FULFILLMENT%
            </Text>
            <View style={{ width: "30%"}}>
            <View style={{ width: "100%", borderBottom: "1px solid black"}}>
            <Text
              style={{
                padding: "4px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              REVENUE             
            </Text>
            </View>
            <View style={{flexDirection: "row", width: "100%"}}>
            
            <Text
              style={{
                padding: "4px",
                width: "33.33%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              SALES
            </Text>
            <Text
              style={{
                padding: "4px",
                width: "33.33%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              EXPORT
            </Text>
            <Text
              style={{
                padding: "4px",
                width: "33.33%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              BALANCE
            </Text>
            </View>
            </View>
            <Text
              style={{
                paddingTop: 9,
                fontSize: "9px",
                width: "10%",
                color: "red",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              REVENUE FULFILLMENT%
            </Text>
           
          </View>

          <View>
            {result &&
              result.length &&
              result.map((item1, index) => {
                
                const customHeight = item1?.data.length < 2 ? "40px" : "auto";

               
                const subtotalOrderQty =
                  item1.data && item1.data.length
                    ? item1.data.reduce(
                        (totalValue, currentValue) =>
                          totalValue + Number(currentValue?.order_qty),
                        0
                      )
                    : 0;
                grandOrderQty += subtotalOrderQty;

                const subtotalExFactQty =
                  item1.data && item1?.data.length
                    ? item1?.data?.reduce(
                        (totalValue, currentValue) =>
                          totalValue + Number(currentValue?.exfac_qty),
                        0
                      )
                    : null;
                      grandExFactQty += subtotalExFactQty;

                      const subtotalQtyBalValue =  subtotalOrderQty - subtotalExFactQty;

                      grandQtyBalValue += subtotalQtyBalValue;

                const subtotalSalesValue =
                  item1.data && item1.data.length
                    ? item1.data.reduce(
                        (totalValue, currentValue) =>
                          totalValue + Number(currentValue?.order_value),
                        0
                      )
                    : null;

                    grandSalesValue += subtotalSalesValue;

                const subtotalExportValue =
                  item1.data && item1.data.length
                    ? item1.data.reduce(
                        (totalValue, currentValue) =>
                          totalValue + Number(currentValue?.ship_value),
                        0
                      )
                    : null;
                    grandExportValue += subtotalExportValue;

                
               const subtotalRevBalValue = subtotalSalesValue - subtotalExportValue;
                grandRevBalValue += subtotalRevBalValue;
                return (
                  <View
                    key={index}
                    style={{
                      width: "100%",
                    }}
                  >
                    <View wrap={false} style={{flexDirection: "row", width: "100%"}}>
                     
                      <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "10%",
                              borderLeft: "1px solid black",
                              borderBottom: "1px solid black",
                              height: "auto",
                            }}
                          >
                            <Text style={{
                               padding: 1,
                               fontSize: 7,
                               fontWeight: "bold",
                               fontFamily: "Times",
                               textAlign: "center",
                            }}>
                              {item1?.buyer_name}
                            </Text>
                          </View>
                    <View style={{width: "90%", height: "auto"}}>
                      
                    {item1?.data.length
                      ? item1?.data?.map((i2, i) => {
                          count = count + 1;

                          return (
                            <View style={{height: "auto"}}>
                              <TableData key={i} item={i2} index={i} customHeight={customHeight} />
                            </View>
                          );
                        })
                      : null}

                    </View>
                    </View>
                    <View
                      wrap={false}
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "10px",
                          height: "auto",
                          width: "20%",
                          fontWeight: "bold",
                          fontFamily: "Times",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {"Sub Total"}
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "9px",
                          height: "auto",
                          width: "10%",
                          fontWeight: "bold",
                          fontFamily: "Times",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {subtotalOrderQty}
                      </Text>
                     
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "9px",
                          height: "auto",
                          fontWeight: "bold",
                          fontFamily: "Times",
                          width: "9.98%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {subtotalExFactQty}
                      </Text>
                     
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "9px",
                          height: "auto",
                          width: "9.99%",
                          fontWeight: "bold",
                          fontFamily: "Times",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {currencyName &&
                        currencyName?.["0"]?.["primary_currency_symbol"]
                          ? currencyName?.["0"]?.["primary_currency_symbol"]
                          : '$'}{" "}
                        {Number(subtotalQtyBalValue).toFixed(2)}
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "9px",
                          height: "auto",
                          fontWeight: "bold",
                          fontFamily: "Times",
                          width: "10%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                       
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "9px",
                          height: "auto",
                          width: "9.99%",
                          fontWeight: "bold",
                          fontFamily: "Times",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {currencyName &&
                        currencyName?.["0"]?.["primary_currency_symbol"]
                          ? currencyName?.["0"]?.["primary_currency_symbol"]
                          : "$"}{" "}
                        {Number(subtotalSalesValue).toFixed(2)}
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "9px",
                          height: "auto",
                          width: "9.99%",
                          fontWeight: "bold",
                          fontFamily: "Times",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {currencyName &&
                        currencyName?.["0"]?.["primary_currency_symbol"]
                          ? currencyName?.["0"]?.["primary_currency_symbol"]
                          : "$"}{" "}
                        {Number(subtotalExportValue).toFixed(2)}
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "9px",
                          height: "auto",
                          width: "9.99%",
                          fontWeight: "bold",
                          fontFamily: "Times",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {currencyName &&
                        currencyName?.["0"]?.["primary_currency_symbol"]
                          ? currencyName?.["0"]?.["primary_currency_symbol"]
                          : "$"}{" "}
                        {Number(subtotalRevBalValue).toFixed(2)}
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "9px",
                          height: "auto",
                          width: "10%",
                          borderRight: "1px solid black",
                          fontWeight: "bold",
                          fontFamily: "Times",
                          textAlign: "center",
                        }}
                      >
                        
                       
                      </Text>
                    </View>
                  </View>
                );
              })}
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  height: "auto",
                  width: "20%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Grand Total
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  width: "10%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {grandOrderQty}
              </Text>
             
             
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                 
                {grandExFactQty}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["primary_currency_symbol"]
                  ? currencyName?.["0"]?.["primary_currency_symbol"]
                  : "$"}{" "}
                {Number(grandQtyBalValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
               
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                        currencyName?.["0"]?.["primary_currency_symbol"]
                          ? currencyName?.["0"]?.["primary_currency_symbol"]
                          : "$"}{" "}
                        {Number(grandSalesValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["primary_currency_symbol"]
                  ? currencyName?.["0"]?.["primary_currency_symbol"]
                  : "$"}{" "}
                {Number(grandExportValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["primary_currency_symbol"]
                  ? currencyName?.["0"]?.["primary_currency_symbol"]
                  : "$"}{" "}
                {Number(grandRevBalValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "10%",
                  textAlign: "center",
                }}
              >
               
              </Text>
            </View>
          </View>
        </View>

        {/* Signature part */}
        <View
        wrap={false}
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Helvetica",
    minWidth: "110vw",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
