import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import MyDocument from "./MyDocument";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [invoiceData2, setInvoiceData2] = React.useState([]);

  React.useEffect(() => {
    document.title = "Order Summary";
  }, []);

  const location = useLocation();
  let { id } = useParams();
  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getOrderSumInfo = localStorage.getItem("order_summary")
    ? JSON.parse(localStorage.getItem("order_summary"))
    : null;

  const order_valueInfo = localStorage.getItem("order_value")
    ? JSON.parse(localStorage.getItem("order_value"))
    : null;

  // React.useEffect(() => {
  //   if (getOrderSumInfo) {
  //     setInvoiceData(getOrderSumInfo);
  //   }
  // }, [getOrderSumInfo]);

  console.log("gggggggggg", getOrderSumInfo, order_valueInfo);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={getOrderSumInfo ? getOrderSumInfo : null}
        getOrderSumInfo={order_valueInfo ? order_valueInfo : null}
        getInfo={getInfo}
      />
    </PDFViewer>
  );
};

export default Invoice;
