import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";
// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const reportHeaderData =
    invoiceData && invoiceData.length ? invoiceData[0]?.data[0]?.data : {};
  const tableBodyData = invoiceData && invoiceData.length ? invoiceData : [];
  const currencyName =
    invoiceData && invoiceData.length ? invoiceData[0]?.data[0]?.data : {};

  // totalRDLFob
  const totalRDLFob =
    tableBodyData && tableBodyData.length
      ? tableBodyData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.rdl_fob),
          0
        )
      : 0;
  // totalRDLValue
  const totalRDLValue =
    tableBodyData && tableBodyData.length
      ? tableBodyData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.rdl_value),
          0
        )
      : 0;

  // totalFactValue
  const totalFactValue =
    tableBodyData && tableBodyData.length
      ? tableBodyData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.factory_value),
          0
        )
      : 0;

  // totalCommission
  const totalCommission =
    tableBodyData && tableBodyData.length
      ? tableBodyData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.comm_value),
          0
        )
      : 0;
  // totalCommission
  const totalOtherCommission =
    tableBodyData && tableBodyData.length
      ? tableBodyData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.oth_comm),
          0
        )
      : 0;
  // totalCommission
  const totalOverCommission =
    tableBodyData && tableBodyData.length
      ? tableBodyData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.ovr_comm),
          0
        )
      : 0;

  const totalActualCommission =
    tableBodyData && tableBodyData.length
      ? tableBodyData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.actual_comm),
          0
        )
      : 0;

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  let count = 0;

  const TableData = ({ item }) => {
    return (
      <View
        wrap={false}
        break={count > 6}
        // key={index}
        style={{
          flexDirection: "row",
          width: "100%",
          // border: "1px solid black",
          borderRight: "1px solid black",
          borderLeft: "1px solid black",
          borderBottom: "0.5px solid black",
          borderTop: "0.5px solid black",
        }}
      >
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.37%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.style}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.buyer_po ?? 0}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "8.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.qty ? item?.qty : 0}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "5.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : " "}{" "}
          {item?.rdl_fob ? Number(item?.rdl_fob).toFixed(2) : "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "8.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : " "}{" "}
          {item?.rdl_value ? Number(item?.rdl_value).toFixed(2) : "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "5.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : " "}{" "}
          {item?.factory_fob ? Number(item?.factory_fob).toFixed(2) : "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "8.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : " "}{" "}
          {item?.factory_value
            ? Number(item?.factory_value).toFixed(2)
            : "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "8.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.comm_per ? Number(item?.comm_per).toFixed(2) : "0.00"} %
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "8.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : " "}{" "}
          {item?.comm_value ? Number(item?.comm_value).toFixed(2) : "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "8.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : " "}{" "}
          {item?.ovr_comm ? Number(item?.ovr_comm).toFixed(2) : "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "8.33%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : " "}{" "}
          {item?.oth_comm ? Number(item?.oth_comm).toFixed(2) : "0.00"}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "8.33%",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : " "}{" "}
          {item?.actual_comm ? Number(item?.actual_comm).toFixed(2) : "0.00"}
        </Text>
      </View>
    );
  };

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        {/* Header Info */}
        <View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            {/* <Image
              style={{
                width: "885px",
                height: "75px",
                textAlign: "center",
                margin: "auto",
              }}
              src={logo}
            /> */}
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>
              Renaissance Designs Ltd.
            </Text>
            <Text style={{ fontSize: 9, paddingTop: 3, paddingBottom: 4 }}>
              House 14,Road 2,Sector 3,Uttara,Dhaka,Bangladesh
            </Text>
            <View
              style={{
                margin: "auto",
                height: "40px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  // backgroundColor: "rgb(56, 56, 141)",
                  width: "220px",
                  color: "black",
                  padding: "6px",
                  borderBottom: "1px",
                }}
              >
                Sales Contract Commission
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "-60px",
              marginBottom: "20px",
            }}
          >
            <View>
              <Text style={{ fontSize: "9px" }}>
                Print Date :{" "}
                {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
              </Text>

              <Text style={{ fontSize: "9px" }}>
                Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
              </Text>
            </View>
          </View>
          <View
            style={{
              height: "40px",
              marginTop: "20px",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <View style={{}}>
              <Text
                style={{
                  fontSize: "10px",
                  fontFamily: "Times",
                  fontWeight: 900,
                  paddingBottom: 2,
                }}
              >
                Buyer Name
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontFamily: "Times",
                  fontWeight: 900,
                  paddingBottom: 2,
                }}
              >
                Sales Contract No
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
                :{" "}
                {reportHeaderData && reportHeaderData?.[0]
                  ? reportHeaderData?.[0]?.buyer_name
                  : ""}
              </Text>
              <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
                :{" "}
                {reportHeaderData && reportHeaderData?.[0]
                  ? reportHeaderData?.[0]?.sales_contract_no
                  : ""}
              </Text>
            </View>

            <View>
              <Text
                style={{ fontSize: "10px", fontWeight: 900, paddingBottom: 2 }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontFamily: "Times",
                  fontWeight: 900,
                  paddingBottom: 2,
                }}
              >
                Sales Contract Date
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: "10px", paddingBottom: 2 }}> </Text>
              <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
                : {""}
                {new Date(
                  reportHeaderData
                    ? reportHeaderData?.[0]?.sales_contract_date
                    : null
                ).toLocaleDateString(["es-CL", "id"])}
              </Text>
            </View>
          </View>
        </View>
        {/* TABLE PART */}

        {tableBodyData &&
          tableBodyData.length &&
          tableBodyData.map((tb_data, index) => {
            let grandTotalOrderQty = 0;
            let grandTotalRDLValue = 0;
            let grandTotalFactValue = 0;
            let grandTotalCommValue = 0;
            let grandTotalOverCommValue = 0;
            let grandTotalOtherCommValue = 0;
            let grandTotalRDLCommValue = 0;

            console.log("tb_data", tb_data);

            return (
              <View key={index} style={{ marginTop: "20px" }}>
                <View wrap={false}>
                  {/* amendment data */}
                  <View
                    wrap={false}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 2,
                      height: 30,
                    }}
                  >
                    <View style={{ flexGrow: 1 }}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Times",
                          fontSize: 10,
                          marginBottom: 2,
                        }}
                      >
                        Amendment No :{tb_data?.amd_no}
                      </Text>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Times",
                          fontSize: 10,
                          marginBottom: 2,
                        }}
                      >
                        Amendment Date :{" "}
                        {new Date(tb_data?.amd_date).toLocaleDateString([
                          "es-CL",
                          "id",
                        ])}
                      </Text>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Times",
                          fontSize: 10,
                          marginBottom: 5,
                        }}
                      >
                        Remarks :{tb_data?.remarks}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Times",
                        fontSize: 10,
                        marginBottom: 2,
                      }}
                    >
                      Amendment By : {tb_data?.amd_by}
                    </Text>
                  </View>
                  {/* table header */}
                  <View
                    style={{
                      // marginTop: 10,
                      border: "1px solid black",
                      flexDirection: "row",
                      width: "100%",
                      height: "35px",
                      backgroundColor: "#d3cfcf",
                    }}
                    wrap={false}
                    fixed
                  >
                    <Text
                      style={{
                        padding: "4px 3px",
                        width: "11.37%",
                        fontSize: "10px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        paddingTop: 10,
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Style
                    </Text>
                    <Text
                      style={{
                        padding: "4px 3px",
                        width: "11.33%",
                        fontSize: "10px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        paddingTop: 10,
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Buyer PO
                    </Text>
                    <Text
                      style={{
                        padding: "4px 3px",
                        fontSize: "10px",
                        width: "8.33%",
                        paddingTop: 10,
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Order Qty
                    </Text>
                    <Text
                      style={{
                        padding: "4px 3px",
                        width: "5.33%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        fontSize: "10px",
                        textAlign: "center",
                      }}
                    >
                      RDL {"\n"} Fob
                    </Text>
                    <Text
                      style={{
                        padding: "4px 3px",
                        fontSize: "10px",
                        width: "8.33%",
                        paddingTop: 10,
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      RDL Value
                    </Text>
                    <Text
                      style={{
                        padding: "4px 3px",
                        fontSize: "10px",
                        width: "5.33%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Fac {"\n"} Fob
                    </Text>
                    <Text
                      style={{
                        padding: "4px 3px",
                        fontSize: "10px",
                        width: "8.33%",
                        paddingTop: 10,
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Fac Value
                    </Text>
                    <Text
                      style={{
                        padding: "4px 0",
                        fontSize: "10px",
                        width: "8.33%",
                        borderRight: "1px solid black",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        textAlign: "center",
                      }}
                    >
                      Commission {"\n"} (%)
                    </Text>
                    <Text
                      style={{
                        padding: "4px 3px",
                        fontSize: "10px",
                        width: "8.33%",
                        borderRight: "1px solid black",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        textAlign: "center",
                      }}
                    >
                      Commission Value
                    </Text>

                    <Text
                      style={{
                        padding: "4px 3px",
                        fontSize: "10px",
                        width: "8.33%",
                        borderRight: "1px solid black",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        textAlign: "center",
                      }}
                    >
                      Oversease Commission
                    </Text>
                    <Text
                      style={{
                        padding: "4px 3px",
                        fontSize: "10px",
                        width: "8.33%",
                        borderRight: "1px solid black",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        textAlign: "center",
                      }}
                    >
                      Other Commission
                    </Text>
                    <Text
                      style={{
                        padding: "4px 3px",
                        fontSize: "10px",
                        width: "8.33%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        textAlign: "center",
                      }}
                    >
                      RDL Commission
                    </Text>
                  </View>
                </View>

                <View>
                  {tb_data &&
                    tb_data?.data?.length &&
                    tb_data?.data.map((item1, index) => {
                      // subtotalOrderQty && grandTotalOrderQty
                      const subtotalOrderQty =
                        item1.data && item1.data.length
                          ? item1.data.reduce(
                              (totalValue, currentValue) =>
                                totalValue + Number(currentValue?.qty),
                              0
                            )
                          : 0;

                      grandTotalOrderQty += subtotalOrderQty;

                      // subtotalRDLValue && grandTotalRDLValue
                      const subtotalRDLValue =
                        item1.data && item1?.data.length
                          ? item1?.data?.reduce(
                              (totalValue, currentValue) =>
                                totalValue + Number(currentValue?.rdl_value),
                              0
                            )
                          : null;

                      grandTotalRDLValue += subtotalRDLValue;

                      // subtotalFacValue && grandTotalFactValue
                      const subtotalFacValue =
                        item1.data && item1.data.length
                          ? item1.data.reduce(
                              (totalValue, currentValue) =>
                                totalValue +
                                Number(currentValue?.factory_value),
                              0
                            )
                          : null;
                      grandTotalFactValue += subtotalFacValue;
                      // subtotalCommValue && grandTotalCommValue
                      const subtotalCommValue =
                        item1.data && item1.data.length
                          ? item1.data.reduce(
                              (totalValue, currentValue) =>
                                totalValue + Number(currentValue?.comm_value),
                              0
                            )
                          : null;
                      grandTotalCommValue += subtotalCommValue;

                      // subtotalOverCommValue && grandTotalOverCommValue
                      const subtotalOverCommValue =
                        item1.data && item1.data.length
                          ? item1.data.reduce(
                              (totalValue, currentValue) =>
                                totalValue + Number(currentValue?.ovr_comm),
                              0
                            )
                          : null;
                      grandTotalOverCommValue += subtotalOverCommValue;

                      // subtotalOtherCommValue && grandTotalOtherCommValue
                      const subtotalOtherCommValue =
                        item1.data && item1.data.length
                          ? item1.data.reduce(
                              (totalValue, currentValue) =>
                                totalValue + Number(currentValue?.oth_comm),
                              0
                            )
                          : null;
                      grandTotalOtherCommValue += subtotalOtherCommValue;

                      // subtotalRDLCommValue && grandTotalRDLCommValue
                      const subtotalRDLCommValue =
                        item1.data && item1.data.length
                          ? item1.data.reduce(
                              (totalValue, currentValue) =>
                                totalValue + Number(currentValue?.actual_comm),
                              0
                            )
                          : null;
                      grandTotalRDLCommValue += subtotalRDLCommValue;

                      return (
                        <View
                          key={index}
                          style={{
                            width: "100%",
                          }}
                        >
                          <View wrap={false}>
                            <Text
                              style={{
                                padding: "7px 0 5px 10px",
                                fontSize: "11px",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                color: "black",
                                height: "auto",
                                width: "100%",
                                border: "1px solid black",
                              }}
                            >
                              {item1?.dm_order_no}
                            </Text>
                          </View>
                          {item1 && item1?.data?.length
                            ? item1?.data?.map((i2, i) => {
                                count = count + 1;
                                return (
                                  <View>
                                    <TableData key={i} item={i2} />
                                  </View>
                                );
                              })
                            : null}
                          <View
                            wrap={false}
                            style={{
                              borderBottom: "0.5px solid black",
                              borderLeft: "1px solid black",
                              borderTop: "0.5px solid black",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "9px",
                                height: "auto",
                                width: "22.67%",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {"Sub Total"}
                            </Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                width: "8.33%",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {subtotalOrderQty}
                            </Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                width: "5.33%",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {""}
                            </Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                width: "8.33%",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {currencyName &&
                              currencyName?.["0"]?.["primary_currency_symbol"]
                                ? currencyName?.["0"]?.[
                                    "primary_currency_symbol"
                                  ]
                                : ""}{" "}
                              {Number(subtotalRDLValue).toFixed(2)}
                            </Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                width: "5.32%",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            ></Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                width: "8.32%",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {currencyName &&
                              currencyName?.["0"]?.["primary_currency_symbol"]
                                ? currencyName?.["0"]?.[
                                    "primary_currency_symbol"
                                  ]
                                : ""}{" "}
                              {Number(subtotalFacValue).toFixed(2)}
                            </Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                width: "8.30%",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {/* {currencyName &&
                              currencyName?.["0"]?.["primary_currency_symbol"]
                                ? currencyName?.["0"]?.[
                                    "primary_currency_symbol"
                                  ]
                                : ""}{" "} */}
                              {(
                                (Number(subtotalCommValue) * 100) /
                                Number(subtotalRDLValue)
                              ).toFixed(2)}{" "}
                              %
                            </Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                width: "8.32%",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {currencyName &&
                              currencyName?.["0"]?.["primary_currency_symbol"]
                                ? currencyName?.["0"]?.[
                                    "primary_currency_symbol"
                                  ]
                                : ""}{" "}
                              {Number(subtotalCommValue).toFixed(2)}
                            </Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                width: "8.33%",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {currencyName &&
                              currencyName?.["0"]?.["primary_currency_symbol"]
                                ? currencyName?.["0"]?.[
                                    "primary_currency_symbol"
                                  ]
                                : ""}{" "}
                              {Number(subtotalOverCommValue).toFixed(2)}
                              {"\n"}
                              {`(${(
                                (((Number(subtotalCommValue) * 100) /
                                  Number(subtotalRDLValue)) *
                                  Number(subtotalOverCommValue)) /
                                Number(subtotalCommValue)
                              ).toFixed(2)} %)`}
                            </Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                width: "8.33%",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                borderRight: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {currencyName &&
                              currencyName?.["0"]?.["primary_currency_symbol"]
                                ? currencyName?.["0"]?.[
                                    "primary_currency_symbol"
                                  ]
                                : ""}{" "}
                              {Number(subtotalOtherCommValue).toFixed(2)}
                              {"\n"}
                              {`(${(
                                (((Number(subtotalCommValue) * 100) /
                                  Number(subtotalRDLValue)) *
                                  Number(subtotalOtherCommValue)) /
                                Number(subtotalCommValue)
                              ).toFixed(2)} %)`}
                            </Text>
                            <Text
                              style={{
                                padding: "5px",
                                fontSize: "8px",
                                height: "auto",
                                width: "8.40%",
                                borderRight: "1px solid black",
                                fontWeight: "bold",
                                fontFamily: "Times",
                                textAlign: "center",
                              }}
                            >
                              {currencyName &&
                              currencyName?.["0"]?.["primary_currency_symbol"]
                                ? currencyName?.["0"]?.[
                                    "primary_currency_symbol"
                                  ]
                                : ""}{" "}
                              {Number(subtotalRDLCommValue).toFixed(2)}
                              {"\n"}
                              {`(${(
                                (((Number(subtotalCommValue) * 100) /
                                  Number(subtotalRDLValue)) *
                                  Number(subtotalRDLCommValue)) /
                                Number(subtotalCommValue)
                              ).toFixed(2)} %)`}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                      borderTop: "0.5px solid black",
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "12px",
                        height: "auto",
                        width: "22.67%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Grand Total
                    </Text>

                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        width: "8.33%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {grandTotalOrderQty}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        width: "5.35%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        width: "8.33%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {currencyName &&
                      currencyName?.["0"]?.["primary_currency_symbol"]
                        ? currencyName?.["0"]?.["primary_currency_symbol"]
                        : " "}{" "}
                      {grandTotalRDLValue.toFixed(2)}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        width: "5.35%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        width: "8.33%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {currencyName &&
                      currencyName?.["0"]?.["primary_currency_symbol"]
                        ? currencyName?.["0"]?.["primary_currency_symbol"]
                        : " "}{" "}
                      {grandTotalFactValue.toFixed(2)}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        width: "8.33%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {/* {currencyName &&
                      currencyName?.["0"]?.["primary_currency_symbol"]
                        ? currencyName?.["0"]?.["primary_currency_symbol"]
                        : " "}{" "} */}
                      {(
                        (Number(grandTotalCommValue) * 100) /
                        Number(grandTotalRDLValue)
                      ).toFixed(2)}{" "}
                      %
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        width: "8.33%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {currencyName &&
                      currencyName?.["0"]?.["primary_currency_symbol"]
                        ? currencyName?.["0"]?.["primary_currency_symbol"]
                        : " "}{" "}
                      {grandTotalCommValue.toFixed(2)}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        width: "8.33%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {currencyName &&
                      currencyName?.["0"]?.["primary_currency_symbol"]
                        ? currencyName?.["0"]?.["primary_currency_symbol"]
                        : " "}{" "}
                      {grandTotalOverCommValue.toFixed(2)}
                      {"\n"}
                      {`(${(
                        (((Number(grandTotalCommValue) * 100) /
                          Number(grandTotalRDLValue)) *
                          Number(grandTotalOverCommValue)) /
                        Number(grandTotalCommValue)
                      ).toFixed(2)} %)`}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        width: "8.33%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {currencyName &&
                      currencyName?.["0"]?.["primary_currency_symbol"]
                        ? currencyName?.["0"]?.["primary_currency_symbol"]
                        : " "}{" "}
                      {grandTotalOtherCommValue.toFixed(2)}
                      {"\n"}
                      {`(${(
                        (((Number(grandTotalCommValue) * 100) /
                          Number(grandTotalRDLValue)) *
                          Number(grandTotalOtherCommValue)) /
                        Number(grandTotalCommValue)
                      ).toFixed(2)} %)`}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "9px",
                        height: "auto",
                        fontFamily: "Times",
                        fontWeight: "bold",
                        width: "8.33%",
                        textAlign: "center",
                      }}
                    >
                      {currencyName &&
                      currencyName?.["0"]?.["primary_currency_symbol"]
                        ? currencyName?.["0"]?.["primary_currency_symbol"]
                        : " "}{" "}
                      {grandTotalRDLCommValue.toFixed(2)}
                      {"\n"}
                      {`(${(
                        (((Number(grandTotalCommValue) * 100) /
                          Number(grandTotalRDLValue)) *
                          Number(grandTotalRDLCommValue)) /
                        Number(grandTotalCommValue)
                      ).toFixed(2)} %)`}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}

        {/* Signature part */}
        <View
          wrap={false}
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Helvetica",
    minWidth: "110vw",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
