import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

const Quixote = ({ invoiceData, getInfo }) => {
  const reportHeaderData =
    invoiceData && invoiceData.length ? invoiceData[0]?.["0"] : {};
  const tableBodyData = invoiceData && invoiceData.length ? invoiceData[1] : {};

  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : [];

  const totalOrderQty =
    tableBodyDataRefine &&
    tableBodyDataRefine.length &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.dm_invoice_qty),
      0
    );
  // totalInvoiceValue
  const totalInvoiceValue =
    tableBodyDataRefine &&
    tableBodyDataRefine.length &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.dm_invoice_value),
      0
    );

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: 16, fontWeight: "bold", fontFamily: "Times" }}>
              Renaissance Designs Ltd
            </Text>
            <Text style={{ fontSize: 9, paddingTop: 3, paddingBottom: 4 }}>
              House 14,Road 2,Sector 3,Uttara,Dhaka,Bangladesh
            </Text>
            <Text style={{ fontSize: 10, marginTop: 4 }}>
              COMMERCIAL INVOICE
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-40px",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: "9px" }}>Print Date : {defaultValue}</Text>
            <Text style={{ fontSize: "9px" }}>
              Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
            </Text>
          </View>
        </View>

        {/* Header Info */}
        <View
          style={{
            marginTop: "50px",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <View style={{ width: "55%" }}>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{ fontSize: 10, paddingBottom: 2, paddingRight: 10 }}
              >
                INVOICE NUMBER:
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  marginTop: -5,
                  textAlign: "center",
                  border: "1px solid black",
                  padding: 4,
                  width: 212,
                }}
              >
                {reportHeaderData?.dm_invoice_no}
              </Text>
            </View>

            <Text
              style={{
                fontSize: 9,
                fontWeight: 900,
                fontFamily: "Times",
                paddingBottom: 2,
                paddingTop: 5,
              }}
            >
              Beneficiary Name & Address:
            </Text>
            <View
              style={{
                border: "1px solid black",
                flexDirection: "column",
                width: 310,
                height: 50, // daynamic habe
                padding: 3,
              }}
            >
              <Text style={{ fontSize: 10, paddingTop: 3 }}>
                Renaissance Designs Ltd
              </Text>
              <Text style={{ fontSize: 10, paddingTop: 3 }}>
                House 14,Road 2,Sector 3,Uttora,Dhaka,Bangladesh
              </Text>
            </View>
            <Text
              style={{
                fontSize: 9,
                paddingBottom: 2,
                paddingTop: 5,
                fontWeight: 900,
                fontFamily: "Times",
              }}
            >
              Buyer Name & Address
            </Text>
            <View
              style={{
                border: "1px solid black",
                flexDirection: "column",
                width: 310,
                height: 60, // daynamic habe
                padding: 3,
              }}
            >
              <Text style={{ fontSize: 9, paddingTop: 3 }}>
                {reportHeaderData?.buyer_name}
              </Text>
              <Text style={{ fontSize: 9, paddingTop: 3 }}>
                {reportHeaderData?.buyer_addr}
              </Text>
            </View>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 900,
                fontFamily: "Times",
                paddingTop: 16,
                paddingBottom: 4,
              }}
            >
              Beneficiary Bank Details:
            </Text>
            <Text style={{ fontSize: 9, paddingBottom: 4 }}>
              {reportHeaderData?.bank_name} (
              {reportHeaderData?.payment_type?.toLowerCase() === "lc"
                ? reportHeaderData?.lc_bank_branch_name
                : reportHeaderData?.sc_bank_branch_name}
              )
            </Text>
            <Text style={{ fontSize: 9, paddingBottom: 4 }}>
              {reportHeaderData?.payment_type?.toLowerCase() === "lc"
                ? reportHeaderData?.lc_bank_address
                : reportHeaderData?.sc_bank_address}
            </Text>

            <View style={{ flexDirection: "row" }}>
              <View>
                <Text style={{ fontSize: 9, paddingBottom: 4 }}>SWIFT:</Text>
                <Text style={{ fontSize: 9, paddingBottom: 4 }}>
                  ACCOUNT NO
                </Text>
              </View>
              <View style={{ paddingLeft: 70 }}>
                <Text style={{ fontSize: 9, paddingBottom: 4 }}>
                  {reportHeaderData?.payment_type?.toLowerCase() === "lc"
                    ? reportHeaderData?.lc_bank_swift
                    : reportHeaderData?.sc_bank_swift}
                </Text>
                <Text style={{ fontSize: 9, paddingBottom: 4 }}>
                  {reportHeaderData?.payment_type?.toLowerCase() === "lc"
                    ? reportHeaderData?.lc_bank_ac_no
                    : reportHeaderData?.sc_bank_ac_no}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ width: "45%" }}>
            <View style={{ flexDirection: "row", paddingBottom: 10 }}>
              <Text style={{ fontSize: 10, paddingRight: 14 }}>
                INVOICE DATE:
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  marginTop: -5,
                  textAlign: "center",
                  border: "1px solid black",
                  padding: 4,
                  height: "auto",
                  width: 120,
                }}
              >
                {reportHeaderData?.dm_invoice_date}
              </Text>
            </View>
            <View style={{ flexDirection: "row", paddingBottom: 10 }}>
              <Text style={{ fontSize: 10, paddingRight: 36 }}>LC/TT No:</Text>
              <Text
                style={{
                  fontSize: 10,
                  marginTop: -5,
                  border: "1px solid black",
                  padding: 4,
                  height: "auto",
                  width: 190,
                }}
              >
                {reportHeaderData?.payment_type?.toLowerCase() === "lc"
                  ? reportHeaderData?.lc_no
                  : reportHeaderData?.sales_contract_no}
              </Text>
            </View>

            <View style={{ flexDirection: "row", paddingBottom: 4 }}>
              <Text style={{ fontSize: 10, paddingRight: 30 }}>
                LC/TT Date:
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  marginTop: -5,
                  border: "1px solid black",
                  padding: 4,
                  height: "auto",
                  width: 190,
                }}
              >
                {reportHeaderData?.payment_type?.toLowerCase() === "lc"
                  ? reportHeaderData?.lc_open_date
                  : reportHeaderData?.sales_contract_date}
              </Text>
            </View>

            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: 275,
                marginTop: 13,
              }}
            >
              <View style={{ width: 150, borderRight: "1px solid black" }}>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 2,
                    height: "auto",
                    borderBottom: "1px solid black",
                  }}
                >
                  Payment Terms:{" "}
                </Text>
                <Text style={{ fontSize: 10, padding: 2, height: "auto" }}>
                  Terms Of Delivery:{" "}
                </Text>
              </View>
              <View style={{ width: 125 }}>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 2,
                    height: "auto",
                    borderBottom: "1px solid black",
                  }}
                >
                  {reportHeaderData?.payment_type
                    ? reportHeaderData?.payment_type?.toUpperCase()
                    : " "}
                </Text>
                <Text style={{ fontSize: 10, padding: 2, height: "auto" }}>
                  {reportHeaderData?.term_of_delivery
                    ? reportHeaderData?.term_of_delivery
                    : " "}{" "}
                </Text>
              </View>
            </View>
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: 275,
                marginTop: 8,
              }}
            >
              <View style={{ width: 150, borderRight: "1px solid black" }}>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 2,
                    height: "auto",
                    borderBottom: "1px solid black",
                  }}
                >
                  INCOTERMS:{" "}
                </Text>
                <Text style={{ fontSize: 10, padding: 2, height: "auto" }}>
                  COUNTRY OF ORIGIN:
                </Text>
              </View>
              <View style={{ width: 125 }}>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 2,
                    height: "auto",
                    borderBottom: "1px solid black",
                  }}
                >
                  {reportHeaderData?.payment_type
                    ? reportHeaderData?.payment_type?.toUpperCase()
                    : " "}
                </Text>
                <Text style={{ fontSize: 10, padding: 2, height: "auto" }}>
                  {"Bangladesh"}
                </Text>
              </View>
            </View>

            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: 275,
                marginTop: 8,
              }}
            >
              <View style={{ width: 150, borderRight: "1px solid black" }}>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 2,
                    height: "auto",
                    borderBottom: "1px solid black",
                  }}
                >
                  PLACE OF RECEIPT:
                </Text>
                <Text style={{ fontSize: 10, padding: 2, height: "auto" }}>
                  PORT OF LOADING:{" "}
                </Text>
              </View>

              <View style={{ width: 125 }}>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 2,
                    height: "auto",
                    borderBottom: "1px solid black",
                  }}
                >
                  {/* CHILE */}{" "}
                </Text>
                <Text style={{ fontSize: 10, padding: 2, height: "auto" }}>
                  {" "}
                  {/* CHILE */}{" "}
                </Text>
              </View>
            </View>

            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: 275,
                marginTop: 8,
                marginBottom: 14,
              }}
            >
              <View style={{ width: 150, borderRight: "1px solid black" }}>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 2,
                    height: "auto",
                    borderBottom: "1px solid black",
                  }}
                >
                  FINAL DESTINATION:{" "}
                </Text>
                <Text style={{ fontSize: 10, padding: 2, height: "auto" }}>
                  PORT OF DESTINATION:{" "}
                </Text>
              </View>
              <View style={{ width: 125 }}>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 2,
                    height: "auto",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  {/* CHILE */}{" "}
                </Text>
                <Text style={{ fontSize: 10, padding: 2, height: "auto" }}>
                  {" "}
                  {/* CHILE */}{" "}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "4px 3px",
                width: "15%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Brand
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "20%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Order/Po No
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "20%",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                fontSize: "10px",
                textAlign: "center",
              }}
            >
              Style No
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                width: "15%",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Quantity
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                width: "15%",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Unit Cost
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                width: "15%",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              Total Cost
            </Text>
          </View>
          <View>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length &&
              tableBodyDataRefine.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  break={index > 1}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "9px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.brand}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "9px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.buyer_po}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "9px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.style}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "9px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.dm_invoice_qty}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "9px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    $ {item?.dm_invoice_fob}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "9px",
                      height: "auto",
                      width: "15%",
                      textAlign: "center",
                    }}
                  >
                    $ {item?.dm_invoice_value}
                  </Text>
                </View>
              ))}
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  height: "auto",
                  width: "440px",
                  paddingLeft: "340px",
                  borderRight: "1px solid black",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                Total
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  width: "15%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalOrderQty ?? 0}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  width: "15%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  width: "15%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  textAlign: "center",
                }}
              >
                $ {totalInvoiceValue.toFixed(2)}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  width: "680px",
                  height: "auto",
                  paddingLeft: "340px",
                  borderRight: "1px solid black",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                Less
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  width: "15%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  textAlign: "center",
                }}
              >
                $ {reportHeaderData?.dm_less_value ?? 0}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  width: "680px",
                  height: "auto",
                  paddingLeft: "340px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                }}
              >
                Grand Total
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  width: "15%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  textAlign: "center",
                }}
              >
                {/* $ {totalInvoiceValue.toFixed(2)} */}${" "}
                {(
                  totalInvoiceValue - `${reportHeaderData?.dm_less_value ?? 0}`
                ).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            marginTop: 30,
            justifyContent: "space-between",
          }}
        >
          <View></View>
          <View
            wrap={false}
            style={{
              width: 300,
              flexDirection: "row",
              border: "1px solid black",
            }}
          >
            <View style={{ width: 140 }}>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  paddingLeft: 2,
                  paddingTop: 5,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                TOTAL PCS:
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  paddingLeft: 2,
                  paddingTop: 5,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                TOTAL CTN:
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  paddingLeft: 2,
                  paddingTop: 5,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                TOTAL N.W:
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  paddingLeft: 2,
                  paddingTop: 5,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                TOTAL G.W:
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  paddingLeft: 2,
                  paddingTop: 5,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                }}
              >
                TOTAL CBM:
              </Text>
            </View>
            <View style={{ width: 100 }}>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 50,
                  paddingTop: 5,
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                {totalOrderQty ?? 0}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 2,
                  paddingTop: 5,
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              ></Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 2,
                  paddingTop: 5,
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              ></Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 2,
                  paddingTop: 5,
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                }}
              ></Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 2,
                  paddingTop: 5,
                  borderRight: "1px solid black",
                }}
              ></Text>
            </View>
            <View style={{ width: 60 }}>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 2,
                  paddingTop: 5,
                  borderBottom: "1px solid black",
                }}
              >
                PCS
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 2,
                  paddingTop: 5,
                  borderBottom: "1px solid black",
                }}
              >
                CTN
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 2,
                  paddingTop: 5,
                  borderBottom: "1px solid black",
                }}
              >
                PCS
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 2,
                  paddingTop: 5,
                  borderBottom: "1px solid black",
                }}
              >
                KGS
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  height: 20,
                  fontWeight: "bold",
                  fontFamily: "Times",
                  paddingLeft: 2,
                  paddingTop: 5,
                }}
              >
                CBM
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};



Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 20,
    fontFamily: "Helvetica",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
