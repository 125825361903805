import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

const Quixote = ({ invoiceData, state, month }) => {
  const tableBodyHeader = invoiceData && invoiceData.length && invoiceData[0];

  const tableBodyData = invoiceData && invoiceData.length && invoiceData;

  const parameter = tableBodyHeader && tableBodyHeader?.data;

  // parameters
  let buyerParameter = 0;
  if (state?.buyer) {
    buyerParameter++;
  } else {
    if (buyerParameter > 0) {
      buyerParameter--;
    }
  }

  let deprtParameter = 0;
  if (state?.department) {
    deprtParameter++;
  } else {
    if (deprtParameter > 0) {
      deprtParameter--;
    }
  }

  let teamParameter = 0;
  if (state?.team) {
    teamParameter++;
  } else {
    if (teamParameter > 0) {
      teamParameter--;
    }
  }
  let factoryParameter = 0;
  if (state?.factory) {
    factoryParameter++;
  } else {
    if (factoryParameter > 0) {
      factoryParameter--;
    }
  }

  let porductParameter = 0;
  if (state?.product_type) {
    porductParameter++;
  } else {
    if (porductParameter > 0) {
      porductParameter--;
    }
  }

  // results
  let quantityResult = 0;
  if (state?.quantity) {
    quantityResult++;
  } else {
    if (quantityResult > 0) {
      quantityResult--;
    }
  }

  let rdlValueResult = 0;
  if (state?.rdl_value) {
    rdlValueResult++;
  } else {
    if (rdlValueResult > 0) {
      rdlValueResult--;
    }
  }

  let factoryValueResult = 0;
  if (state?.factory_value) {
    factoryValueResult++;
  } else {
    if (factoryValueResult > 0) {
      factoryValueResult--;
    }
  }
  let commValueResult = 0;
  if (state?.commission_value) {
    commValueResult++;
  } else {
    if (commValueResult > 0) {
      commValueResult--;
    }
  }

  let qtyPercentageResult = 0;
  if (state?.quantity_percentage) {
    qtyPercentageResult++;
  } else {
    if (qtyPercentageResult > 0) {
      qtyPercentageResult--;
    }
  }

  // parameter Summation
  const totalParameterSum =
    buyerParameter +
    deprtParameter +
    teamParameter +
    factoryParameter +
    porductParameter;

  // result Summation
  const totalResultSum =
    quantityResult +
    rdlValueResult +
    factoryValueResult +
    commValueResult +
    qtyPercentageResult;

  const countParameter = totalParameterSum ? totalParameterSum : 1;
  const countResult = totalResultSum ? totalResultSum : 1;

  // Factory Total Calculations

  let exportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      return (
        item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          exportQtyArr.push(
            item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
          );
        })
      );
    });

  let commissionVArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      return (
        item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          commissionVArr.push(item3?.comm_value);
        })
      );
    });
  let rdlValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      return (
        item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          rdlValueArr.push(item3?.rdl_value);
        })
      );
    });
  let factoryValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      return (
        item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          factoryValueArr.push(item3?.factory_value);
        })
      );
    });

  let sl_no = 0;

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap={true}>
        {/* Header Info */}

        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View style={{ width: "100%", textAlign: "center" }}>
            <Text style={{ fontSize: 16, marginBottom: 5, fontWeight: "bold" }}>
              EXPORT REPORT
            </Text>
            <Text style={{ fontSize: 9 }}>Renaissance Designs Ltd</Text>
            <Text style={{ fontSize: 9, paddingTop: 4, paddingBottom: 4 }}>
              House 14,Road 2,Sector 3,Uttara,Dhaka,Bangladesh
            </Text>
          </View>
        </View>

        {/* Header Info */}
        <View
          style={{
            marginTop: "30px",
            paddingLeft: 20,
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "550x",
              textAlign: "center",
              border: "1px solid black",
            }}
          >
            <View
              style={{ flexDirection: "row", borderBottom: "1px solid black" }}
            >
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "120px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  borderBottom: 0,
                }}
              >
                Report Type
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "330px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: 0,
                }}
              >
                Export Report
              </Text>
            </View>
            <View
              style={{ flexDirection: "row", borderBottom: "1px solid black" }}
            >
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "120px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  borderBottom: 0,
                }}
              >
                Sales Year
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "330px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: 0,
                }}
              >
                {state ? state?.year : null}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "120px",
                  fontWeight: "bold",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  borderBottom: 0,
                }}
              >
                Sales Month{" "}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "330px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: 0,
                }}
              >
                {month}
              </Text>
            </View>
          </View>
        </View>
        {/* TABLE PART */}
        <View style={{ marginTop: "30px" }}>
          <View fixed>
            {/* First Header */}
            <View
              style={{
                border: "1px solid black",
                borderBottom: 0,
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  width: "50.1%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                Month
              </Text>
              <Text
                style={{
                  padding: 5,
                  width: "50%",
                  fontSize: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {month ? month : " "}
              </Text>
            </View>
            {/* Second Header */}
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  textAlign: "center",
                  alignItems: "center",
                  width: "2%",
                  padding: 5,
                }}
              >
                SL
              </Text>

              {state && state?.buyer ? (
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    width: `${48 / countParameter}%`,
                    borderLeft: "1px solid black",
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  Buyer
                </Text>
              ) : null}

              {state && state?.department ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${48 / countParameter}%`,
                    fontSize: 10,
                    borderLeft: "1px solid black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Dept
                </Text>
              ) : null}

              {state && state?.team ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${48 / countParameter}%`,
                    fontSize: 10,
                    borderLeft: "1px solid black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Team
                </Text>
              ) : null}

              {state && state?.factory ? (
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    width: `${48 / countParameter}%`,
                    borderLeft: "1px solid black",
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  Factory
                </Text>
              ) : null}

              {state && state?.product_type ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${48 / countParameter}%`,
                    fontSize: 9,
                    fontWeight: "bold",
                    borderLeft: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Product Type
                </Text>
              ) : null}

              {state && state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}
              {state && state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state && state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state && state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}
              {state && state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  {/* Quantity(%) of Total */}
                </Text>
              ) : null}
            </View>
          </View>

          {/* Table Body Part Start */}
          <View>
            {tableBodyData &&
              tableBodyData.length &&
              tableBodyData.map((item, index) => {
                if (item.buyer_name) {
                  sl_no++;
                }
                return item?.buyer_name ? (
                  <View
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "0.5px solid black",
                      flexDirection: "row",
                      width: "100%",
                    }}
                    key={index}
                    wrap={false}
                    break={index > 11}
                  >
                    {/* parameter */}
                    <View
                      style={{
                        width: "2%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                      }}
                    >
                      <Text
                        style={{
                          padding: 5,
                          fontSize: 9,
                          textAlign: "center",
                        }}
                      >
                        {sl_no}
                      </Text>
                    </View>
                    {state?.buyer ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          width: `${48 / countParameter}%`,
                          borderLeft: "1px solid black",
                          height: "auto",
                        }}
                      >
                        <Text
                          style={{
                            padding: 5,
                            fontSize: 9,
                            textAlign: "center",
                          }}
                        >
                          {item?.buyer_name}
                        </Text>
                      </View>
                    ) : null}

                    {state?.team ? (
                      <View
                        style={{
                          width: `${48 / countParameter}%`,
                          height: "auto",
                          borderLeft: "1px solid black",
                        }}
                      >
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i2) => {
                              return (
                                <Text
                                  key={i2}
                                  style={{
                                    padding: 5,
                                    fontSize: 9,
                                    textAlign: "center",
                                    borderBottom: `${
                                      i2 + 1 === item?.data.length
                                        ? null
                                        : "1px solid black"
                                    }`,
                                  }}
                                >
                                  {item2?.team_name}
                                </Text>
                              );
                            })
                          : null}
                      </View>
                    ) : null}
                    {state?.factory ? (
                      <View
                        style={{
                          width: `${48 / countParameter}%`,
                          height: "auto",
                          borderLeft: "1px solid black",
                        }}
                      >
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i2) => {
                              return (
                                <Text
                                  key={i2}
                                  style={{
                                    padding: 5,
                                    fontSize: 9,
                                    textAlign: "center",
                                    borderBottom: `${
                                      i2 + 1 === item?.data.length
                                        ? null
                                        : "1px solid black"
                                    }`,
                                  }}
                                >
                                  {item2?.factory_name}
                                </Text>
                              );
                            })
                          : null}
                      </View>
                    ) : null}
                    {state?.department ? (
                      <View
                        style={{
                          width: `${48 / countParameter}%`,
                          height: "auto",
                          borderLeft: "1px solid black",
                        }}
                      >
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i2) => {
                              return (
                                <Text
                                  key={i2}
                                  style={{
                                    padding: 5,
                                    fontSize: 9,
                                    textAlign: "center",
                                    borderBottom: `${
                                      i2 + 1 === item?.data.length
                                        ? null
                                        : "1px solid black"
                                    }`,
                                  }}
                                >
                                  {item2?.department}
                                </Text>
                              );
                            })
                          : null}
                      </View>
                    ) : null}
                    {state?.product_type ? (
                      <View
                        style={{
                          width: `${48 / countParameter}%`,
                          height: "auto",
                          borderLeft: "1px solid black",
                        }}
                      >
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i2) => {
                              return (
                                <Text
                                  key={i2}
                                  style={{
                                    padding: 5,
                                    fontSize: 9,
                                    textAlign: "center",
                                    borderBottom: `${
                                      i2 + 1 === item?.data.length
                                        ? null
                                        : "1px solid black"
                                    }`,
                                  }}
                                >
                                  {item2?.product_type}
                                </Text>
                              );
                            })
                          : null}
                      </View>
                    ) : null}
                    {/* result */}
                    {state?.quantity ? (
                      <View
                        style={{
                          width: `${50 / countResult}%`,
                          height: "auto",
                          borderLeft: "1px solid black",
                        }}
                      >
                        {item?.data && item?.data?.length
                          ? item?.data?.map((item2, i2) => {
                              var counts = 0;
                              if (item2.month == state?.month_id) {
                                return (
                                  <Text
                                    style={{
                                      padding: 5,
                                      fontSize: 9,
                                      textAlign: "center",
                                      borderBottom: `${
                                        counts === item?.data?.length
                                          ? null
                                          : "1px solid black"
                                      }`,
                                    }}
                                  >
                                    {item2?.sales_qty
                                      ? item2?.sales_qty
                                      : item2?.export_qty}
                                  </Text>
                                );
                              }
                            })
                          : null}
                      </View>
                    ) : null}
                    {state?.rdl_value ? (
                      <View
                        style={{
                          width: `${50 / countResult}%`,
                          height: "auto",
                          borderLeft: "1px solid black",
                        }}
                      >
                        {item?.data && item?.data?.length
                          ? item?.data?.map((item2, i2) => {
                              var counts = 0;
                              if (item2.month == state?.month_id) {
                                return (
                                  <Text
                                    style={{
                                      padding: 5,
                                      fontSize: 9,
                                      textAlign: "center",
                                      borderBottom: `${
                                        counts === item?.data?.length
                                          ? null
                                          : "1px solid black"
                                      }`,
                                    }}
                                  >
                                    ${" "}
                                    {item2?.rdl_value
                                      ? Number(item2?.rdl_value).toFixed(2)
                                      : "0.00"}
                                  </Text>
                                );
                              }
                            })
                          : null}
                      </View>
                    ) : null}
                    {state?.factory_value ? (
                      <View
                        style={{
                          width: `${50 / countResult}%`,
                          height: "auto",
                          borderLeft: "1px solid black",
                        }}
                      >
                        {item?.data && item?.data?.length
                          ? item?.data?.map((item2, i2) => {
                              var counts = 0;
                              if (item2.month == state?.month_id) {
                                return (
                                  <Text
                                    style={{
                                      padding: 5,
                                      fontSize: 9,
                                      textAlign: "center",
                                      borderBottom: `${
                                        counts === item?.data?.length
                                          ? null
                                          : "1px solid black"
                                      }`,
                                    }}
                                  >
                                    ${" "}
                                    {item2?.factory_value
                                      ? Number(item2?.factory_value).toFixed(2)
                                      : "0.00"}
                                  </Text>
                                );
                              }
                            })
                          : null}
                      </View>
                    ) : null}
                    {state?.commission_value ? (
                      <View
                        style={{
                          width: `${50 / countResult}%`,
                          height: "auto",
                          borderLeft: "1px solid black",
                        }}
                      >
                        {item?.data && item?.data?.length
                          ? item?.data?.map((item2, i2) => {
                              var counts = 0;
                              if (item2.month == state?.month_id) {
                                return (
                                  <Text
                                    style={{
                                      padding: 5,
                                      fontSize: 9,
                                      textAlign: "center",
                                      borderBottom: `${
                                        counts === item?.data?.length
                                          ? null
                                          : "1px solid black"
                                      }`,
                                    }}
                                  >
                                    ${" "}
                                    {item2?.comm_value
                                      ? Number(item2?.comm_value).toFixed(2)
                                      : "0.00"}
                                  </Text>
                                );
                              }
                            })
                          : null}
                      </View>
                    ) : null}
                    {state?.quantity_percentage ? (
                      <View
                        style={{
                          width: `${50 / countResult}%`,
                          height: "auto",
                          borderLeft: "1px solid black",
                        }}
                      >
                        {/* {item?.data && item?.data?.length
                          ? item?.data?.map((item2, i2) => {
                              var counts = 0;
                              if (item2.month == state.month_id) {
                                return (
                                  <Text
                                    style={{
                                      padding: 5,
                                      fontSize: 9,
                                      textAlign: "center",
                                      borderBottom: `${
                                        counts === item?.data?.length
                                          ? null
                                          : "1px solid black"
                                      }`,
                                    }}
                                  >
                                    {item2?.comm_per
                                      ? Number(item2?.comm_per).toFixed(2)
                                      : "0.00"}{" "}
                                    %
                                  </Text>
                                );
                              }
                            })
                          : null} */}
                      </View>
                    ) : null}
                  </View>
                ) : null;
              })}
            {/* factory total calculation */}
            <View
              wrap={false}
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "0.5px solid black",
                borderBottom: "1px solid black",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 900,
                  width: `50%`,
                  textAlign: "center",
                  padding: 5,
                }}
              >
                Total
              </Text>
              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  {exportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  ${" "}
                  {rdlValueArr
                    ?.reduce((totalV, currentV) => totalV + Number(currentV), 0)
                    .toFixed(2) ?? 0}
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  ${" "}
                  {factoryValueArr
                    ?.reduce((totalV, currentV) => totalV + Number(currentV), 0)
                    .toFixed(2) ?? 0}
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  ${" "}
                  {commissionVArr
                    ?.reduce((totalV, currentV) => totalV + Number(currentV), 0)
                    .toFixed(2) ?? 0}
                </Text>
              ) : null}
              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              ) : null}
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 20,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Arial",
    minWidth: "120vw",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
