import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";

// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const reportHeaderData =
    invoiceData && invoiceData.length ? invoiceData[0] : {};
  const tableBodyData1 =
    invoiceData && invoiceData.length ? invoiceData[1] : {};

  const tableBodyData2 =
    invoiceData && invoiceData.length ? invoiceData[2] : {};
  const discountValue = invoiceData && invoiceData.length ? invoiceData[3] : {};

  const refineTableBodyData1 = tableBodyData1
    ? Object.keys(tableBodyData1).map((key) => tableBodyData1[key])
    : [];

  const tableBodyDataRefine2 = tableBodyData2
    ? Object.keys(tableBodyData2).map((key) => tableBodyData2[key])
    : [];

  console.log("invoiceData", invoiceData);

  const totalOrderQty =
    refineTableBodyData1 &&
    refineTableBodyData1.length &&
    refineTableBodyData1.reduce(
      (totalValue, currentValue) => totalValue + Number(currentValue?.quantity),
      0
    );

  let countPage = 1;

  let grandTotal = 0;
  refineTableBodyData1 &&
    refineTableBodyData1?.map((item, i) => {
      const fobRate =
        reportHeaderData && reportHeaderData?.["0"]?.["purpose"] === "c"
          ? Number(item?.rdl_fob) - Number(item?.factory_fob)
          : Number(item?.fob_transfer_rate) - Number(item?.factory_fob);
      grandTotal += Number(item?.quantity) * fobRate;
    });

  grandTotal =
    Number(grandTotal) +
    (discountValue?.["0"]?.["discount_amt"]
      ? Number(discountValue?.["0"]?.["discount_amt"])
      : 0);

  console.log("grandTotal: ", grandTotal);

  const BDTTaka =
    (
      grandTotal -
      ((grandTotal * Number(tableBodyDataRefine2[0]?.processing_charge)) / 100 +
        Number(tableBodyDataRefine2[0]?.less_amt) +
        (grandTotal * Number(tableBodyDataRefine2[0]?.adjustment)) / 100)
    ).toFixed(2) *
    (tableBodyDataRefine2
      ? Number(tableBodyDataRefine2?.["0"]?.["currency_rate"])
      : 1);

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              marginBottom: "20px",
              marginTop: -20,
            }}
          >
            <Image
              style={{
                width: "555px",
                height: "75px",
                textAlign: "center",
                margin: "auto",
              }}
              src={logo}
            />
            <View
              style={{
                margin: "auto",
                height: "40px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "200px",
                  padding: "6px",
                }}
              >
                DEBIT NOTE
              </Text>
            </View>
          </View>

          {/* Header Info */}
          <View
            style={{
              height: "80px",
              marginTop: -20,
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: "10px",
                  fontFamily: "Times",
                  fontWeight: 900,
                  paddingBottom: 3,
                }}
              >
                To{" "}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontFamily: "Times",
                  fontWeight: 900,
                  paddingBottom: 3,
                }}
              >
                {reportHeaderData && reportHeaderData?.["0"]?.["factory_name"]
                  ? (reportHeaderData?.["0"]?.["factory_name"]).toUpperCase()
                  : " "}
              </Text>
              <Text style={{ fontSize: "10px", width: "250px" }}>
                {reportHeaderData &&
                reportHeaderData?.["0"]?.["factory_address"]
                  ? (reportHeaderData?.["0"]?.["factory_address"]).toUpperCase()
                  : " "}
              </Text>
            </View>

            <View>
              <Text
                style={{
                  fontSize: "10px",
                  fontFamily: "Times",
                  fontWeight: 900,
                  paddingBottom: 3,
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontFamily: "Times",
                  fontWeight: 900,
                  paddingBottom: 3,
                }}
              >
                Ref No :{" "}
                <Text
                  style={{
                    fontSize: "10px",
                    fontFamily: "Helvetica",
                    fontWeight: "normal",
                  }}
                >
                  {reportHeaderData && reportHeaderData?.["0"]?.["ref_no"]
                    ? reportHeaderData?.["0"]?.["ref_no"]
                    : " "}
                </Text>
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontFamily: "Times",
                  fontWeight: 900,
                  paddingBottom: 3,
                }}
              >
                Date :{" "}
                <Text
                  style={{
                    fontSize: "10px",
                    fontFamily: "Helvetica",
                    fontWeight: "normal",
                  }}
                >
                  {new Date(
                    reportHeaderData &&
                      reportHeaderData?.["0"]?.["document_date"]
                  ).toLocaleDateString(["es-CL", "id"])}
                </Text>
              </Text>
            </View>
          </View>
        </View>

        {/* TABLE PART */}
        <View>
          {/* First Table */}
          <View
            style={{
              width: "100%",
            }}
          >
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
              fixed
              wrap={false}
            >
              <Text
                style={{
                  padding: "10px 1px 0 1px",
                  width: "7%",
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                SL No
              </Text>

              <Text
                style={{
                  padding: "10px 1px 0 1px",
                  width: "28%",
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Style and Invoice Number
              </Text>
              <Text
                style={{
                  padding: "4px 3px",
                  fontSize: "11px",
                  width: "13%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Quantity (pcs)
              </Text>
              <Text
                style={{
                  padding: "4px 3px",
                  width: "13%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  fontSize: "11px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {reportHeaderData &&
                reportHeaderData?.["0"]?.["purpose"] === "c"
                  ? "RDL Unit Price"
                  : "LC Transfer Unit Price $"}
              </Text>
              <Text
                style={{
                  padding: "4px 3px",
                  width: "13%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  fontSize: "11px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {reportHeaderData &&
                reportHeaderData?.["0"]?.["purpose"] === "c"
                  ? "Factory Unit Price"
                  : "F. Agreed Unit Price (USD)"}
              </Text>
              <Text
                style={{
                  padding: "4px 3px",
                  width: "13%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  fontSize: "11px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Margin Per Unit
              </Text>
              <Text
                style={{
                  padding: "4px 3px",
                  width: "13%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  fontSize: "11px",
                  textAlign: "center",
                }}
              >
                {reportHeaderData &&
                reportHeaderData?.["0"]?.["purpose"] === "c"
                  ? "Total Margin (USD)"
                  : "Total Excess ($)"}
              </Text>
            </View>

            <View>
              {refineTableBodyData1 &&
                refineTableBodyData1.length > 0 &&
                refineTableBodyData1?.map((item, index) => {
                  return (
                    <View
                      wrap={false}
                      break={index > 9}
                      key={index}
                      style={{
                        flexDirection: "row",
                        borderBottom: `${
                          (index === 9 && countPage === 1) ||
                          index + 1 === refineTableBodyData1.length
                            ? "1px solid black"
                            : 0
                        }`,
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          padding: "5px 0",
                          fontSize: "9px",
                          width: "7%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </Text>
                      <Text
                        style={{
                          fontSize: "9px",
                          padding: "5px 3px 5px 10px",
                          width: "28%",
                          borderRight: "1px solid black",
                        }}
                      >
                        {item?.style}
                      </Text>

                      <Text
                        style={{
                          padding: "5px 0",
                          width: "13%",
                          borderRight: "1px solid black",
                          fontSize: "9px",
                          textAlign: "center",
                        }}
                      >
                        {item?.quantity ? item?.quantity : 0}
                      </Text>

                      <Text
                        style={{
                          padding: "5px 0",
                          width: "13%",
                          borderRight: "1px solid black",
                          fontSize: "9px",
                          textAlign: "center",
                        }}
                      >
                        {reportHeaderData &&
                        reportHeaderData?.["0"]?.["purpose"] === "c"
                          ? `$ ${
                              item?.rdl_fob
                                ? Number(item?.rdl_fob).toFixed(2)
                                : "0.00"
                            }`
                          : `$ ${
                              item?.rdl_fob
                                ? Number(item?.fob_transfer_rate).toFixed(2)
                                : "0.00"
                            }`}
                      </Text>

                      <Text
                        style={{
                          padding: "5px 0",
                          width: "13%",
                          borderRight: "1px solid black",
                          fontSize: "9px",
                          textAlign: "center",
                        }}
                      >
                        ${" "}
                        {item?.factory_fob
                          ? Number(item?.factory_fob).toFixed(2)
                          : "0.00"}
                      </Text>

                      <Text
                        style={{
                          padding: "5px 0",
                          width: "13%",
                          borderRight: "1px solid black",
                          fontSize: "9px",
                          textAlign: "center",
                        }}
                      >
                        {reportHeaderData &&
                        reportHeaderData?.["0"]?.["purpose"] === "c"
                          ? `$ ${(
                              Number(item?.rdl_fob) - Number(item?.factory_fob)
                            ).toFixed(2)}`
                          : `$ ${(
                              Number(item?.fob_transfer_rate) -
                              Number(item?.factory_fob)
                            ).toFixed(2)}`}
                      </Text>

                      <Text
                        style={{
                          padding: "5px 0",
                          width: "13%",
                          fontSize: "9px",
                          textAlign: "center",
                        }}
                      >
                        {reportHeaderData &&
                        reportHeaderData?.["0"]?.["purpose"] === "c"
                          ? `$ ${(
                              (Number(item?.rdl_fob) -
                                Number(item?.factory_fob)) *
                              Number(item.quantity)
                            ).toFixed(2)}`
                          : `$ ${(
                              (Number(item?.fob_transfer_rate) -
                                Number(item?.factory_fob)) *
                              Number(item.quantity)
                            ).toFixed(2)}`}
                      </Text>
                    </View>
                  );
                })}
              <View
                style={{
                  width: "100%",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderBottom: "1px solid black",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "87%",
                    borderRight: "1px solid black",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Times",
                      padding: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Discount
                  </Text>
                </View>
                <Text
                  style={{
                    fontSize: 10,
                    width: "13%",
                    padding: 5,
                    textAlign: "center",
                  }}
                >
                  ${" "}
                  {discountValue && discountValue?.["0"]?.["discount_amt"]
                    ? Number(discountValue?.["0"]?.["discount_amt"]).toFixed(2)
                    : "0.00"}
                </Text>
              </View>
            </View>
          </View>
          {/* Second Table */}
          <View
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderTop: "0.5px solid black",
              borderBottom: "0.5px solid black",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                paddingTop: "10px",
                fontSize: "10px",
                width: "7%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              {" "}
            </Text>
            <View
              style={{
                width: "28%",
                borderRight: "1px solid black",
                padding: "5px 0",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  padding: "0px 3px 0px 10px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                {"Invoice No: "}
                {"\n"}
                {tableBodyDataRefine2 &&
                  tableBodyDataRefine2.length &&
                  tableBodyDataRefine2?.map((item, index) => (
                    <Text
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        fontFamily: "Helvetica",
                      }}
                    >
                      {item?.invoice_no}{" "}
                      {index + 1 === tableBodyDataRefine2.length ? null : ", "}
                    </Text>
                  ))}
              </Text>

              {reportHeaderData &&
              reportHeaderData?.["0"]?.["purpose"] !== "c" ? (
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    fontFamily: "Times",
                    padding: "10px 0 0 10px",
                  }}
                >
                  LC:{" "}
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "normal",
                      fontFamily: "Helvetica",
                    }}
                  >
                    {tableBodyDataRefine2 && tableBodyDataRefine2[0]?.["lc_no"]}
                  </Text>
                </Text>
              ) : null}
              {reportHeaderData &&
              reportHeaderData?.["0"]?.["purpose"] !== "c" ? (
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    fontFamily: "Times",
                    padding: "0 0 10px 10px",
                  }}
                >
                  Date:{" "}
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "normal",
                      fontFamily: "Helvetica",
                    }}
                  >
                    {tableBodyDataRefine2 &&
                      tableBodyDataRefine2[0]?.["lc_open_date"]}
                  </Text>
                </Text>
              ) : null}

              {reportHeaderData &&
              reportHeaderData?.["0"]?.["purpose"] === "c" ? (
                <Text
                  style={{
                    padding: "10px 3px 0px 10px",
                    fontWeight: "bold",
                    fontFamily: "Times",
                    fontSize: 10,
                  }}
                >
                  Buyer Name:{" "}
                  <Text
                    style={{
                      fontSize: 9,
                      fontWeight: "normal",
                      fontFamily: "Helvetica",
                    }}
                  >
                    {tableBodyDataRefine2[0]?.buyer_name}
                  </Text>
                </Text>
              ) : null}

              {reportHeaderData &&
              reportHeaderData?.["0"]?.["purpose"] !== "c" ? (
                <View>
                  <Text style={{ fontSize: 9, padding: "10 0 0 10px" }}>
                    ESS{" "}
                    <Text
                      style={{
                        fontSize: 10,
                        fontWeight: "bold",
                        fontFamily: "Times",
                      }}
                    >
                      {Number(tableBodyDataRefine2[0]?.adjustment).toFixed(2)}%
                    </Text>
                    EARLIER PAYMENT ADJUSTMENT
                  </Text>
                  <Text style={{ fontSize: 9, padding: "0 0 0 10px" }}>
                    (ON OUR EXCESS VALUE USD{" "}
                    <Text
                      style={{
                        fontSize: 9,
                        fontFamily: "Times",
                        fontWeight: "bold",
                      }}
                    >
                      {grandTotal.toFixed(2)}
                    </Text>
                    )
                  </Text>
                  <Text style={{ fontSize: 9, padding: "0 0 0 10px" }}>
                    Less{" "}
                    <Text style={{ fontSize: 9, fontWeight: "bold" }}>
                      {Number(
                        tableBodyDataRefine2[0]?.processing_charge
                      ).toFixed(2)}
                      %
                    </Text>
                    Processing Charge
                  </Text>
                  <Text style={{ fontSize: 9, padding: "0 0 0 10px" }}>
                    Less{" "}
                    <Text
                      style={{
                        fontSize: 9,
                        fontFamily: "Times",
                        fontWeight: "bold",
                      }}
                    >
                      {Number(tableBodyDataRefine2[0]?.less_amt).toFixed(2)}
                    </Text>{" "}
                    Amount
                  </Text>
                </View>
              ) : null}
            </View>

            {reportHeaderData &&
            reportHeaderData?.["0"]?.["purpose"] === "c" ? (
              <Text
                style={{
                  paddingTop: "5px",
                  fontSize: "11px",
                  width: "13%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {" "}
              </Text>
            ) : (
              <View
                style={{
                  paddingTop: "5px",
                  width: "13%",
                  borderRight: "1px solid black",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                </Text>
                <Text
                  style={{
                    borderTop: "1px solid black",
                    fontSize: "11px",
                    height: "75px",
                    paddingTop: "5px",
                    fontWeight: "bold",
                    fontFamily: "Times",
                    textAlign: "center",
                  }}
                >
                  {totalOrderQty} pcs
                </Text>
              </View>
            )}
            <Text
              style={{
                paddingTop: "5px",
                fontSize: "11px",
                width: "13%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              {" "}
            </Text>
            <Text
              style={{
                paddingTop: "5px",
                fontSize: "11px",
                width: "13%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              {" "}
            </Text>
            <Text
              style={{
                paddingTop: "5px",
                fontSize: "11px",
                width: "13%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              {" "}
            </Text>

            {reportHeaderData &&
            reportHeaderData?.["0"]?.["purpose"] === "c" ? (
              <Text
                style={{
                  paddingTop: "5px",
                  fontSize: "11px",
                  width: "13%",
                  textAlign: "center",
                }}
              >
                {" "}
              </Text>
            ) : (
              <View
                style={{
                  paddingTop: "2px",
                  width: "13%",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      paddingLeft: "2px",
                      fontWeight: "bold",
                      fontFamily: "Times",
                    }}
                  >
                    $
                  </Text>
                  <Text
                    style={{
                      fontSize: "11px",
                      paddingRight: "2px",
                      fontWeight: "bold",
                      fontFamily: "Times",
                    }}
                  >
                    {grandTotal.toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "75px",
                    padding: "0 2px",
                    borderTop: "1px solid black",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        paddingTop: "2px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                      }}
                    >
                      ${" "}
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        paddingTop: "2px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                      }}
                    >
                      {tableBodyDataRefine2[0]?.adjustment
                        ? (
                            (grandTotal *
                              Number(tableBodyDataRefine2[0]?.adjustment)) /
                            100
                          ).toFixed(2)
                        : "0.00"}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        paddingTop: "2px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                      }}
                    >
                      $
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        paddingTop: "2px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                      }}
                    >
                      {tableBodyDataRefine2[0]?.processing_charge
                        ? (
                            (grandTotal *
                              Number(
                                tableBodyDataRefine2[0]?.processing_charge
                              )) /
                            100
                          ).toFixed(2)
                        : "0.00"}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        paddingTop: "2px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                      }}
                    >
                      $
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        paddingTop: "2px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                      }}
                    >
                      {tableBodyDataRefine2[0]?.less_amt
                        ? Number(tableBodyDataRefine2[0]?.less_amt).toFixed(2)
                        : "0.00"}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>

          {/* grand total */}
          <View
            wrap={false}
            style={{
              borderTop: "0.5px solid black",
              borderBottom: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  height: "auto",
                  width: "35%",
                  borderRight: "1px solid black",
                }}
              >
                GRAND TOTAL US DOLLAR
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "13%",
                  borderRight: "1px solid black",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  textAlign: "center",
                }}
              >
                {reportHeaderData &&
                reportHeaderData?.["0"]?.["purpose"] === "c"
                  ? `${totalOrderQty} pcs`
                  : null}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "13%",
                  borderRight: "1px solid black",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  textAlign: "center",
                }}
              ></Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "13%",
                  borderRight: "1px solid black",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  textAlign: "center",
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "13%",
                  borderRight: "1px solid black",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  textAlign: "center",
                }}
              >
                {" "}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "5px 2px",
                  width: "13%",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    height: "auto",
                    fontWeight: "bold",
                    fontFamily: "Times",
                  }}
                >
                  ${" "}
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    height: "auto",
                    fontWeight: "bold",
                    fontFamily: "Times",
                  }}
                >
                  {grandTotal
                    ? (
                        grandTotal -
                        ((grandTotal *
                          Number(tableBodyDataRefine2[0]?.processing_charge)) /
                          100 +
                          Number(tableBodyDataRefine2[0]?.less_amt) +
                          (grandTotal *
                            Number(tableBodyDataRefine2[0]?.adjustment)) /
                            100)
                      ).toFixed(2)
                    : "0.00"}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderTop: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  height: "auto",
                  width: "100%",
                  paddingLeft: 5,
                }}
              >
                USD IN WORD:
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderTop: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  height: "auto",
                  width: "74%",
                }}
              >
                TAKA IN WORD:
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "5px 2px",
                  fontSize: "10px",
                  height: "auto",
                  width: "26%",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    fontFamily: "Times",
                  }}
                >
                  BDT
                </Text>{" "}
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    fontFamily: "Times",
                  }}
                >
                  {BDTTaka ? BDTTaka.toFixed(2) : "0.00"}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderTop: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  height: "auto",
                  width: "30%",
                  paddingLeft: 5,
                  borderRight: "1px solid black",
                }}
              >
                CURRENCY RATE
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "20%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                USD
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "24%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {tableBodyDataRefine2 && tableBodyDataRefine2?.["0"]
                  ? Number(
                      tableBodyDataRefine2?.["0"]?.["currency_rate"]
                    ).toFixed(2)
                  : "0.00"}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "26%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  textAlign: "center",
                }}
              >
                {" "}
              </Text>
            </View>
          </View>
        </View>

        {/* Signature part */}
        <View
          wrap={false}
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "110px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "110px" }}>
                Receiver's Signature
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "110px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "110px" }}>Check By</Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "110px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "110px" }}>Check By</Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "110px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "110px" }}>
                Authorized Singnature
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => {
            if (pageNumber) {
              countPage = pageNumber;
            }
            return `${pageNumber} / ${totalPages}`;
          }}
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Helvetica",
    minWidth: "100vw",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
