import axiosClient from "../axiosClient";
/*-------------------------------------------------*/
/*                  YEARS API CALLS                */
/*-------------------------------------------------*/

export async function retrieveYearsData() {
    return await axiosClient.get("/years");
  }
  
  export async function addYearDB(value) {
    return await axiosClient.post("/years", value);
  }
  
  export async function deleteYearDB(id) {
    return await axiosClient.delete(`/years/${id}`);
  }
  
  export async function bulkDeleteYearDB(ids) {
    return await axiosClient.post(`/years/bulk_delete`, ids);
  }
  
  export async function editYearDB(value) {   
    return await axiosClient.put(`/years/${value.id}`, value);
  }