export const ADD_YEARS = "ADD_YEARS";
export const ADD_YEARS_OK = "ADD_YEARS_OK";
export const ADD_YEARS_ERROR = "ADD_YEARS_ERROR";

export const BEGIN_YEARS_DOWNLOAD = "BEGIN_YEARS_DOWNLOAD";
export const YEARS_DOWNLOAD_OK = "YEARS_DOWNLOAD_OK";
export const YEARS_DOWNLOAD_ERROR = "YEARS_DOWNLOAD_ERROR";

export const RETRIEVE_YEARS_DELETE = "RETRIEVE_YEARS_DELETE";
export const YEARS_DELETED_OK = "YEARS_DELETED_OK";
export const YEARS_DELETED_ERROR = "YEARS_DELETED_ERROR";

export const RETRIEVE_YEARS_EDIT = "RETRIEVE_YEARS_EDITED";
export const BEGIN_EDIT_YEARS = "BEGIN_EDIT_YEARS";
export const YEARS_EDITED_OK = "YEARS_EDITED_OK";
export const YEARS_EDITED_ERROR = "YEARS_EDITED_ERROR";

export const RETRIEVE_YEARS_BULK_DELETE = "RETRIEVE_YEARS_BULK_DELETE";
export const YEARS_BULK_DELETED_OK = "YEARS_BULK_DELETED_OK";
export const YEARS_BULK_DELETED_ERROR = "YEARS_BULK_DELETED_ERROR";