import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import { useLocation } from "react-router-dom";
import MyDocument from "./MyDocument";

const Invoice = () => {
  const location = useLocation();
  React.useEffect(() => {
    document.title = "Statement Of LC Closing";
  }, []);
  const [invoiceData, setInvoiceData] = React.useState(null);

  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const refineData = localStorage.getItem("lc_sc_closing")
    ? JSON.parse(localStorage.getItem("lc_sc_closing"))
    : null;

  console.log(refineData);

  React.useEffect(() => {
    if (refineData) {
      fetch(`https://dm-eco-api.clonestudiobd.com/api/lc_closing/doc`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          p_id: refineData?.p_id,
        }),
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            setInvoiceData(result);
          });
        }
      });
    }
  }, [location]);

  console.log("invoiceData", invoiceData);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument invoiceData={invoiceData} getInfo={getInfo} />
    </PDFViewer>
  );
};

export default Invoice;
