import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_YEARS,
  BEGIN_EDIT_YEARS,
  BEGIN_YEARS_DOWNLOAD,
  RETRIEVE_YEARS_BULK_DELETE,
  RETRIEVE_YEARS_DELETE
} from "../../constants/modules/yearsConstants";

import {
  addYearErrorAction,
  addYearOkAction,
  bulkDeleteYearErrorAction,
  bulkDeleteYearOkAction,
  deleteYearErrorAction,
  deleteYearOkAction,
  downloadYearsErrorAction,
  downloadYearsOkAction,
  editYearErrorAction,
  editYearOkAction
} from "../../actions/modules/yearsActions";

import {
  addYearDB,
  bulkDeleteYearDB,
  deleteYearDB,
  editYearDB,
  retrieveYearsData,
} from "../../../config/apiCalls/yearsAPI";

// Retrieve products
// worker saga
export function* retrieveYears() {
  try {
    const response = yield call(retrieveYearsData);

    if (response.status === 200) {
      yield put(downloadYearsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadYearsErrorAction());
  }
}

// Create new product
// worker saga
export function* addYear(action) {
  const year = action.year;

  try {
    const response = yield call(addYearDB, year);

    if (response.status === 201) {
      yield delay(500);

      yield put(addYearOkAction(year));
      toast.success("Year created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addYearErrorAction(true));
    toast.error("Year created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteYear(action) {
  const id = action.payload;
  try {
    yield call(deleteYearDB, id);
    yield deleteYearOkAction();
    toast.success("Year deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteYearErrorAction();
  }
}

// Edit product
// worker saga
export function* editYear(action) {
  const year = action.year;

  try {
    yield call(editYearDB, year);
    yield editYearOkAction(year);
    // Alert
    // Alert
    toast.success("Year has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editYearErrorAction();
  }
}

// BULK SAGAS
export function* bulkYearDelete(action) {
  const year = action.year;

  try {
    const response = yield call(bulkDeleteYearDB, year);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteYearOkAction(year));
      toast.success("Bulk Year deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteYearErrorAction(true));
    toast.error("Bulk Year deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchYearsAsync() {
  yield takeLatest(BEGIN_YEARS_DOWNLOAD, retrieveYears);
  yield takeLatest(ADD_YEARS, addYear);
  yield takeLatest(RETRIEVE_YEARS_DELETE, deleteYear);
  yield takeLatest(BEGIN_EDIT_YEARS, editYear);
  yield takeLatest(RETRIEVE_YEARS_BULK_DELETE, bulkYearDelete);
}
