import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";

// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const tableBodyData = invoiceData && invoiceData ? invoiceData[1] : {};
  const currencyName = invoiceData && invoiceData.length ? invoiceData[2] : {};

  console.log("invoiceData", tableBodyData);

  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : [];

  const totalOrderQty =
    tableBodyDataRefine &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) => totalValue + Number(currentValue?.lot_qty),
      0
    );
  // totalFobValue
  const totalFobValue =
    tableBodyDataRefine &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.fob_transfer_value),
      0
    );

  const totalRDLValue =
    tableBodyDataRefine &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.base_dm_value),
      0
    );

  // set default date
  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd
  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Text style={{ fontSize: 16, fontWeight: "bold" }}>
            Renaissance Designs Ltd.
          </Text>
          <Text style={{ fontSize: 9, paddingTop: 3, paddingBottom: 4 }}>
            House 14,Road 2,Sector 3,Uttara,Dhaka,Bangladesh
          </Text>
          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "Times",
                // backgroundColor: "rgb(56, 56, 141)",
                width: "140px",
                color: "black",
                padding: "6px",
                borderBottom: "1px",
              }}
            >
              Factory Order
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-60px",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: "9px" }}>
              Print Date :
              {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
            </Text>
            <Text style={{ fontSize: "9px" }}>
              Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
            </Text>
          </View>
        </View>

        {/* Header Info */}
        <View
          style={{
            height: "90px",
            marginTop: "20px",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Factory Name
            </Text>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Order No
            </Text>

            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Buyer Name
            </Text>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Brand Name
            </Text>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            ></Text>

            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Primary Currency
            </Text>
            <Text
              style={{ fontSize: "10px", fontFamily: "Times", fontWeight: 900 }}
            >
              Session
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["factory_name"]}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["dm_order_no"]}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["buyer_name"]}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["brand"]}
            </Text>

            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["short_name"]}
            </Text>

            <Text style={{ fontSize: "10px" }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["season"]}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Order Date
            </Text>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Fob Type
            </Text>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Team Name
            </Text>

            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Department Name
            </Text>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Secondary Currency
            </Text>
            <Text
              style={{ fontSize: "10px", fontFamily: "Times", fontWeight: 900 }}
            >
              Currency Rate
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              :{" "}
              {invoiceData &&
                new Date(
                  invoiceData[0]?.["0"]?.["order_date"]
                ).toLocaleDateString(["es-CL", "id"])}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["fob_type"]}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["team_name"]}
            </Text>

            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["department"]}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {invoiceData && invoiceData[0]?.["0"]?.["currency_name"]}
            </Text>
            <Text style={{ fontSize: "10px" }}>
              :
              {invoiceData && invoiceData.length
                ? Number(invoiceData[0]?.["0"]?.["currency_rate"]).toFixed(2)
                : null}
            </Text>
          </View>
        </View>
        {/* TABLE PART */}

        <View style={{ marginTop: "20px" }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "4px 3px",
                width: "10%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Style No
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "10%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              PO No
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                width: "6%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Delivery No
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "10%",
                borderRight: "1px solid black",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              Destination
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                width: "10%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Product
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                width: "14%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Color
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                width: "6%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Size
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                width: "10%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Order Qty
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                width: "6%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              RDL FOB
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                width: "10%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              RDL Value
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                width: "6%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Factory FOB
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                width: "10%",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Factory Value
            </Text>
            <Text
              style={{
                padding: "4px 0",
                fontSize: "10px",

                width: "10%",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              Ex-Factory Date
            </Text>
          </View>
          <View style={{ borderTop: "1px solid black" }}>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length &&
              tableBodyDataRefine.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  style={{
                    borderLeft: "1px solid black",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {" "}
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.style}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.buyer_po}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "6%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.buyer_delivery_no}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.port_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.product_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "14%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.color_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "6%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.size_set}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.lot_qty}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "6%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["secondary_currency_symbol"]
                      ? currencyName?.["0"]?.["secondary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.base_dm_rate).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["secondary_currency_symbol"]
                      ? currencyName?.["0"]?.["secondary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.base_dm_value).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "6%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["secondary_currency_symbol"]
                      ? currencyName?.["0"]?.["secondary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.fob_transfer_rate).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["secondary_currency_symbol"]
                      ? currencyName?.["0"]?.["secondary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.fob_transfer_value).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      textAlign: "center",
                    }}
                  >
                    {new Date(item?.ex_factory_date).toLocaleDateString([
                      "es-CL",
                      "id",
                    ])}
                  </Text>
                </View>
              ))}
            <View
              wrap={false}
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "66%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Total
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalOrderQty}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "6%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["secondary_currency_symbol"]
                  ? currencyName?.["0"]?.["secondary_currency_symbol"]
                  : " "}{" "}
                {Number(totalRDLValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "6%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["secondary_currency_symbol"]
                  ? currencyName?.["0"]?.["secondary_currency_symbol"]
                  : " "}{" "}
                {Number(totalFobValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "10%",
                  textAlign: "center",
                }}
              ></Text>
            </View>
          </View>
        </View>

        {/* Signature part */}
        <View
          wrap={false}
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Helvetica",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
