import {
  ADD_YEARS,
  ADD_YEARS_ERROR,
  ADD_YEARS_OK,
  BEGIN_EDIT_YEARS,
  BEGIN_YEARS_DOWNLOAD,
  RETRIEVE_YEARS_BULK_DELETE,
  RETRIEVE_YEARS_DELETE,
  RETRIEVE_YEARS_EDIT,
  YEARS_BULK_DELETED_ERROR,
  YEARS_BULK_DELETED_OK,
  YEARS_DELETED_ERROR,
  YEARS_DELETED_OK,
  YEARS_DOWNLOAD_ERROR,
  YEARS_DOWNLOAD_OK,
  YEARS_EDITED_ERROR,
  YEARS_EDITED_OK,
} from "../../constants/modules/yearsConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteYearAction = (item) => ({
  type: RETRIEVE_YEARS_BULK_DELETE,
  payload: true,
  year: item,
});

export const bulkDeleteYearOkAction = () => ({
  type: YEARS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteYearErrorAction = (state) => ({
  type: YEARS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadYearsAction = () => ({
  type: BEGIN_YEARS_DOWNLOAD,
  payload: true,
});

export const downloadYearsOkAction = (items) => ({
  type: YEARS_DOWNLOAD_OK,
  payload: items,
});

export const downloadYearsErrorAction = () => ({
  type: YEARS_DOWNLOAD_ERROR,
  payload: true,
});

export const addYearAction = (item) => ({
  type: ADD_YEARS,
  payload: true,
  year: item,
});

export const addYearOkAction = () => ({
  type: ADD_YEARS_OK,
  payload: true,
});

export const addYearErrorAction = (state) => ({
  type: ADD_YEARS_ERROR,
  payload: state,
});

export const deleteYearAction = (id) => ({
  type: RETRIEVE_YEARS_DELETE,
  payload: id,
});

export const deleteYearOkAction = () => ({
  type: YEARS_DELETED_OK,
});

export const deleteYearErrorAction = () => ({
  type: YEARS_DELETED_ERROR,
  payload: true,
});

export const retrieveYearAction = (item) => ({
  type: RETRIEVE_YEARS_EDIT,
  payload: item,
});

export const editYearAction = (item) => ({
  type: BEGIN_EDIT_YEARS,
  year: item,
});

export const editYearOkAction = (item) => ({
  type: YEARS_EDITED_OK,
  payload: item,
});

export const editYearErrorAction = () => ({
  type: YEARS_EDITED_ERROR,
  payload: true,
});
