import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import MyDocument from "./MyDocument";

import { useLocation, useParams } from "react-router-dom";

const fakedata = [
  {
    buyer_name: "DMR",
    data: [
      {
        product_type: "SILKEN SEWING LTD.",
        data: [
          {
            buyer_name: "DMR",
            product_type: "SILKEN SEWING LTD.",
            export_qty: "240110",
            comm_value: "240110",
            comm_per: "10",
            factory_value: "55",
            rdl_value: "240110",
            month: "6",
          },
        ],
      },
      {
        product_type: "SILKEN SEWING LTD.",
        data: [
          {
            buyer_name: "DMR",
            product_type: "SILKEN SEWING LTD.",
            export_qty: "240110",
            comm_value: "240110",
            comm_per: "10",
            factory_value: "55",
            rdl_value: "240110",
            month: "6",
          },
        ],
      },
      {
        product_type: "SILKEN SEWING LTD.",
        data: [
          {
            buyer_name: "DMR",
            product_type: "SILKEN SEWING LTD.",
            export_qty: "240110",
            comm_value: "240110",
            comm_per: "10",
            factory_value: "55",
            rdl_value: "240110",
            month: "6",
          },
        ],
      },
      {
        product_type: "SILKEN SEWING LTD.",
        data: [
          {
            buyer_name: "DMR",
            product_type: "SILKEN SEWING LTD.",
            export_qty: "240110",
            comm_value: "240110",
            comm_per: "10",
            factory_value: "55",
            rdl_value: "240110",
            month: "6",
          },
        ],
      },
    ],
  },
  {
    buyer_name: "suburbia",
    data: [
      {
        product_type: "SILKEN SEWING LTD.",
        data: [
          {
            buyer_name: "suburbia",
            product_type: "SILKEN SEWING LTD.",
            export_qty: "240110",
            month: "6",
          },
        ],
      },
    ],
  },
];

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [invoiceData2, setInvoiceData2] = React.useState([]);
  const [state, setState] = React.useState({
    buyer: true,
    department: false,
    team: false,
    factory: false,
    product_type: true,
    quantity: true,
    commission_value: false,
    quantity_percentage: false,
    rdl_value: false,
    factory_value: false,
  });

  React.useEffect(() => {
    document.title = "Sales Based on Ex-Factory";
  }, []);

  const location = useLocation();
  let { id } = useParams();
  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getData = localStorage.getItem("ex_factory_datewise_sales")
    ? JSON.parse(localStorage.getItem("ex_factory_datewise_sales"))
    : null;

  const getState = localStorage.getItem("exfact_state")
    ? JSON.parse(localStorage.getItem("exfact_state"))
    : null;

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={getData ? getData : null}
        state={getState}
        getInfo={getInfo}
        month={id}
      />
    </PDFViewer>
  );
};

export default Invoice;
