import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";

const fakeData = [
  {
    actual_comm: "2000.00",
    buyer_name: "ZSM INDUSTRIA DE CONFECCOES S.A",
    buyer_po: "ZN-RDL-2023-02",
    comm_per: "10.000000",
    comm_value: "4000.00",
    dm_order_no: "RDL/ ZUNE/2023/310",
    factory_fob: "3.600000",
    factory_value: "36000.00",
    oth_comm: null,
    ovr_comm: "2000.00",
    qty: "10000",
    rdl_fob: "4.000000",
    rdl_value: "40000.00",
    sales_contract_date: "2023-09-14",
    sales_contract_no: "RDL/SC/ ZUNE/JFK/23/042",
    style: "RSSVN19",
  },
  {
    actual_comm: "2000.00",
    buyer_name: "ZSM INDUSTRIA DE CONFECCOES S.A",
    buyer_po: "ZN-RDL-2023-02",
    comm_per: "10.000000",
    comm_value: "4000.00",
    dm_order_no: "RDL/ ZUNE/2023/312",
    factory_fob: "3.600000",
    factory_value: "36000.00",
    oth_comm: null,
    ovr_comm: "2000.00",
    qty: "10000",
    rdl_fob: "4.000000",
    rdl_value: "40000.00",
    sales_contract_date: "2023-09-14",
    sales_contract_no: "RDL/SC/ ZUNE/JFK/23/042",
    style: "RSSVN19",
  },
];

// Create styles
const Quixote = ({ invoiceData, getInfo, getOrderSumInfo }) => {
  const tableBodyData =
    invoiceData && invoiceData.length ? invoiceData?.[0] : {};
  const currencyName = invoiceData && invoiceData.length ? invoiceData[1] : {};

  console.log("invoiceData ", invoiceData);
  console.log("getOrderSumInfo", getOrderSumInfo);

  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : fakeData;

  let hash = Object.create(null);

  let result =
    tableBodyDataRefine && tableBodyDataRefine.length !== 0
      ? tableBodyDataRefine.reduce(function (p, c) {
          if (!hash[c.dm_order_no]) {
            hash[c.dm_order_no] = { dm_order_no: c.dm_order_no, data: [] };
            p.push(hash[c.dm_order_no]);
          }
          hash[c.dm_order_no].data.push({
            brand_name: c.brand ? c.brand : "",
            buyer_name: c.buyer_name ? c.buyer_name : "",
            buyer_po: c.buyer_po ? c.buyer_po : "",
            color: c.color ? c.color : "",
            delivery_qty: c.delivery_qty ? c.delivery_qty : "",
            department: c.department ? c.department : "",
            factory_name: c.factory_name ? c.factory_name : "",
            etd_date: c.etd_date ? c.etd_date : "",
            ex_factory_date: c.ex_factory_date ? c.ex_factory_date : "",
            exfactory_date: c.exfactory_date ? c.exfactory_date : "",
            fabrics: c.fabrics ? c.fabrics : "",
            handover_date: c.handover_date ? c.handover_date : "",
            lot_qty: c.lot_qty ? c.lot_qty : "",
            order_date: c.order_date ? c.order_date : "",
            style: c.style ? c.style : "",
            size_set: c.size_set ? c.size_set : "",
            product_name: c.product_name ? c.product_name : "",
            product_type: c.product_type ? c.product_type : "",
            season: c.season ? c.season : "",
            approval_status: c.approval_status ? c.approval_status : "",
            supplier_name: c.supplier_name ? c.supplier_name : "",
          });

          return p;
        }, [])
      : [];

  console.log("hash", result);

  const totalOrderQty =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) => totalValue + Number(currentValue?.qty),
          0
        )
      : 0;

  // totalRDLFob
  const totalRDLFob =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.rdl_fob),
          0
        )
      : 0;
  // totalRDLValue
  const totalRDLValue =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.rdl_value),
          0
        )
      : 0;

  // totalFactValue
  const totalFactValue =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.factory_value),
          0
        )
      : 0;

  // totalCommission
  const totalCommission =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.comm_value),
          0
        )
      : 0;
  // totalCommission
  const totalOtherCommission =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.oth_comm),
          0
        )
      : 0;
  // totalCommission
  const totalOverCommission =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.ovr_comm),
          0
        )
      : 0;

  const totalActualCommission =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.actual_comm),
          0
        )
      : 0;

  let grandTotalBalanceQty = 0;
  let grandTotalDeliveryQty = 0;
  let grandTotalLotQty = 0;

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  let count = 0;
  const TableData = ({ item }) => {
    return (
      <View
        wrap={false}
        break={count > 6}
        // key={index}
        style={{
          flexDirection: "row",
          width: "100%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.factory_name}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.brand_name}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.department}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.season}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "7%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.style}
        </Text>

        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.product_type}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.size_set}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.color}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.fabrics} %
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.supplier_name}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "7%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.buyer_po}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.order_date}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.approval_status}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.ex_factory_date}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.exfactory_date}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.handover_date}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {item?.etd_date}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {Math.abs(item?.lot_qty)}
        </Text>

        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {Math.abs(item?.delivery_qty)}
        </Text>
        <Text
          style={{
            padding: "1px",
            fontSize: "6px",
            height: "auto",
            width: "5%",
            textAlign: "center",
          }}
        >
          {Math.abs(Number(item?.lot_qty) - Number(item?.delivery_qty))}
        </Text>
      </View>
    );
  };

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          {/* <Image
            style={{
              width: "700px",
              height: "75px",
              textAlign: "center",
              margin: "auto",
            }}
            src={logo}
          /> */}
          <Text style={{ fontSize: 16, fontWeight: "bold" }}>
            Renaissance Designs Ltd.
          </Text>
          <Text style={{ fontSize: 9, paddingTop: 3, paddingBottom: 4 }}>
            House 14,Road 2,Sector 3,Uttara,Dhaka,Bangladesh
          </Text>
          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "Times",
                // backgroundColor: "rgb(56, 56, 141)",
                width: "200px",
                color: "black",
                padding: "6px",
                borderBottom: "1px",
              }}
            >
              Order Summary Report
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-60px",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: "9px" }}>
              Print Date :
              {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
            </Text>
            <Text style={{ fontSize: "9px" }}>
              Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
            </Text>
          </View>
        </View>

        {/* Header Info */}
        <View
          style={{
            height: "50px",
            marginTop: "20px",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View style={{}}>
            <Text
              style={{
                fontSize: "10px",
                fontWeight: 900,
                fontFamily: "Times",
                paddingBottom: 2,
              }}
            >
              Report Based On
            </Text>

            <Text
              style={{
                fontSize: "10px",
                fontWeight: 900,
                fontFamily: "Times",
                paddingBottom: 2,
              }}
            >
              Buyer Name
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              :{" "}
              {getOrderSumInfo && getOrderSumInfo?.report_type === 1
                ? "Report Based on Actual Ex-Factory"
                : getOrderSumInfo && getOrderSumInfo?.report_type === 2
                ? "Report Based on Ex-Factory"
                : getOrderSumInfo && getOrderSumInfo?.report_type === 3
                ? "Report Based on Handover"
                : getOrderSumInfo && getOrderSumInfo?.report_type === 4
                ? "Report Based on ETD"
                : ""}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {getOrderSumInfo ? getOrderSumInfo?.buyer_name : ""}
            </Text>
          </View>

          <View>
            <Text
              style={{ fontSize: "10px", fontWeight: 900, paddingBottom: 2 }}
            >
              {" "}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                fontWeight: 900,
                fontFamily: "Times",
                paddingBottom: 2,
              }}
            >
              Date
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}> </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              : {""}
              {new Date(
                getOrderSumInfo ? getOrderSumInfo?.from_date : null
              ).toLocaleDateString(["es-CL", "id"])}{" "}
              to{" "}
              {new Date(
                getOrderSumInfo ? getOrderSumInfo?.to_date : null
              ).toLocaleDateString(["es-CL", "id"])}
            </Text>
          </View>
        </View>
        {/* TABLE PART */}
        <View style={{}}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            wrap={false}
            fixed
          >
            {/* <Text
              style={{
                padding: "4px 3px",
                width: "4.76%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              RDL Ref No
            </Text> */}
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Factory Name
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Brand
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Department
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Season
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "7%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Style Number
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Product Category
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Size Range
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              ColorWay
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Fabric Details
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Fabric Supplier
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "7%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              PO
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Order Date
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              PP Approval Date
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Ex-Factory Date
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Actual Ex-Factory Date
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              HandOver Date
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              ETD Date
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Order Quantity
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Ship Quantity
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",
                fontSize: "8px",
                textAlign: "center",
              }}
            >
              Balance Quantity
            </Text>
          </View>

          <View>
            {result &&
              result.length &&
              result.map((item1, index) => {
                const subtotalLotQty =
                  item1.data && item1.data.length
                    ? item1.data.reduce(
                        (totalValue, currentValue) =>
                          totalValue + Number(currentValue?.lot_qty),
                        0
                      )
                    : null;
                grandTotalLotQty += subtotalLotQty;

                const subtotaldeliveryQty =
                  item1.data && item1.data.length
                    ? item1.data.reduce(
                        (totalValue, currentValue) =>
                          totalValue + Number(currentValue?.delivery_qty),
                        0
                      )
                    : null;
                grandTotalDeliveryQty += subtotaldeliveryQty;
                const subtotalBalanceQty =
                  Number(subtotalLotQty) - Number(subtotaldeliveryQty);

                grandTotalBalanceQty += subtotalBalanceQty;

                return (
                  <View
                    key={index}
                    style={{
                      width: "100%",
                    }}
                  >
                    <View wrap={false}>
                      <Text
                        style={{
                          padding: "7px 0 5px 10px",
                          fontSize: "11px",
                          fontFamily: "Times",
                          fontWeight: "bold",
                          color: "black",
                          height: "auto",
                          width: "100%",
                          border: "1px solid black",
                        }}
                      >
                        {item1?.dm_order_no}
                      </Text>
                    </View>
                    {result && result.length && item1?.data.length
                      ? item1?.data?.map((i2, i) => {
                          count = count + 1;
                          return (
                            <View>
                              <TableData key={i} item={i2} />
                            </View>
                          );
                        })
                      : null}
                    <View
                      wrap={false}
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "9px",
                          height: "auto",
                          width: "88.9%",
                          fontFamily: "Times",
                          fontWeight: "bold",
                          borderRight: "1px solid black",
                          // textAlign: "center",
                        }}
                      >
                        {"Sub Total"}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "8px",
                          height: "auto",
                          width: "5.01%",
                          fontFamily: "Times",
                          fontWeight: "bold",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {/* {currencyName &&
                        currencyName?.["0"]?.["primary_currency_symbol"]
                          ? currencyName?.["0"]?.["primary_currency_symbol"]
                          : ""}{" "} */}

                        {Number(subtotalLotQty)}
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "8px",
                          height: "auto",
                          width: "5%",
                          fontFamily: "Times",
                          fontWeight: "bold",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {/* {currencyName &&
                        currencyName?.["0"]?.["primary_currency_symbol"]
                          ? currencyName?.["0"]?.["primary_currency_symbol"]
                          : ""}{" "} */}
                        {Number(subtotaldeliveryQty)}
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "8px",
                          height: "auto",
                          width: "5.1%",
                          borderRight: "1px solid black",
                          fontFamily: "Times",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {/* {currencyName &&
                        currencyName?.["0"]?.["primary_currency_symbol"]
                          ? currencyName?.["0"]?.["primary_currency_symbol"]
                          : ""}{" "} */}
                        {Number(subtotalBalanceQty)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            <View
              wrap={false}
              style={{
                flexDirection: "row",
                width: "100%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "89%",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  borderRight: "1px solid black",
                  // textAlign: "center",
                }}
              >
                Grand Total
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  width: "5%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {" "}
                {grandTotalLotQty}
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  width: "5%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {grandTotalDeliveryQty}
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  width: "5%",
                  textAlign: "center",
                }}
              >
                {grandTotalBalanceQty}
              </Text>
            </View>
          </View>
        </View>

        {/* Signature part */}
        <View
          wrap={false}
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Helvetica",
    minWidth: "120vw",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
