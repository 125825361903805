import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

const Quixote = ({ invoiceData, state }) => {
  const tableBodyHeader =
    invoiceData && invoiceData.length ? invoiceData[0] : null;
  const tableBodyData = invoiceData && invoiceData.length && invoiceData;
  const parameter = tableBodyHeader && tableBodyHeader?.data;

  console.log("tableBodyData17", tableBodyData);

  // parameters
  let buyerParameter = 0;
  if (tableBodyHeader?.buyer_name) {
    buyerParameter++;
  } else {
    if (buyerParameter > 0) {
      buyerParameter--;
    }
  }

  let deprtParameter = 0;
  if (tableBodyHeader?.department) {
    deprtParameter++;
  } else {
    if (deprtParameter > 0) {
      deprtParameter--;
    }
  }

  let teamParameter = 0;
  if (tableBodyHeader?.team_name) {
    teamParameter++;
  } else {
    if (teamParameter > 0) {
      teamParameter--;
    }
  }
  let factoryParameter = 0;
  if (tableBodyHeader?.factory_name) {
    factoryParameter++;
  } else {
    if (factoryParameter > 0) {
      factoryParameter--;
    }
  }

  let porductParameter = 0;
  if (tableBodyHeader?.product_type) {
    porductParameter++;
  } else {
    if (porductParameter > 0) {
      porductParameter--;
    }
  }

  // results
  let quantityResult = 0;
  if (state?.quantity) {
    quantityResult++;
  } else {
    if (quantityResult > 0) {
      quantityResult--;
    }
  }

  let rdlValueResult = 0;
  if (state?.rdl_value) {
    rdlValueResult++;
  } else {
    if (rdlValueResult > 0) {
      rdlValueResult--;
    }
  }

  let factoryValueResult = 0;
  if (state?.factory_value) {
    factoryValueResult++;
  } else {
    if (factoryValueResult > 0) {
      factoryValueResult--;
    }
  }
  let commValueResult = 0;
  if (state?.commission_value) {
    commValueResult++;
  } else {
    if (commValueResult > 0) {
      commValueResult--;
    }
  }

  let qtyPercentageResult = 0;
  if (state?.quantity_percentage) {
    qtyPercentageResult++;
  } else {
    if (qtyPercentageResult > 0) {
      qtyPercentageResult--;
    }
  }

  // parameter Summation
  const totalParameterSum =
    buyerParameter +
    deprtParameter +
    teamParameter +
    factoryParameter +
    porductParameter;

  // result Summation
  const totalResultSum =
    quantityResult +
    rdlValueResult +
    factoryValueResult +
    commValueResult +
    qtyPercentageResult;

  const countParameter = totalParameterSum ? totalParameterSum : 1;
  const countResult = totalResultSum ? totalResultSum : 1;

  // Factory Total Calculations
  // JANUARY
  let JanExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "1") {
            JanExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let TotalQty =
    tableBodyData &&
    tableBodyData?.map((item2) => {
      return (
        item2?.buyer_name &&
        item2?.data.reduce(
          (totalQty, currentQty) =>
            totalQty +
            Number(
              currentQty?.sales_qty
                ? currentQty?.sales_qty
                : currentQty?.export_qty
            ),
          0
        )
      );
    });

  let JanCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "1") {
            JanCommValArr.push(item3?.comm_value);
          }
        });
    });

  let JanRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "1") {
            JanRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let JanFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "1") {
            JanFactValueArr.push(item3?.factory_value);
          }
        });
    });

  // FEBRUARY
  let FebExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "2") {
            FebExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let FebCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "2") {
            FebCommValArr.push(item3?.comm_value);
          }
        });
    });

  let FebRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "2") {
            FebRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let FebFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "2") {
            FebFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // MARCH
  let MarExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "3") {
            MarExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let MarCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "3") {
            MarCommValArr.push(item3?.comm_value);
          }
        });
    });

  let MarRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "3") {
            MarRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let MarFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "3") {
            MarFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // APRIL
  let AprExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "4") {
            AprExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let AprCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "4") {
            AprCommValArr.push(item3?.comm_value);
          }
        });
    });

  let AprRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "4") {
            AprRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let AprFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "4") {
            AprFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // MAY
  let MayExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "5") {
            MayExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let MayCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "5") {
            MayCommValArr.push(item3?.comm_value);
          }
        });
    });

  let MayRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "5") {
            MayRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let MayFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "5") {
            MayFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // JUNE
  let JunExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "6") {
            JunExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let JunCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "6") {
            JunCommValArr.push(item3?.comm_value);
          }
        });
    });

  let JunRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "6") {
            JunRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let JunFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "6") {
            JunFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // JULY
  let JulExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "7") {
            JulExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let JulCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "7") {
            JulCommValArr.push(item3?.comm_value);
          }
        });
    });

  let JulRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "7") {
            JulRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let JulFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "7") {
            JulFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // AUGUST
  let AugExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "8") {
            AugExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let AugCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "8") {
            AugCommValArr.push(item3?.comm_value);
          }
        });
    });

  let AugRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "8") {
            AugRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let AugFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "8") {
            AugFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // SEPTEMBER
  let SepExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "9") {
            SepExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let SepCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "9") {
            SepCommValArr.push(item3?.comm_value);
          }
        });
    });

  let SepRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "9") {
            SepRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let SepFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "9") {
            SepFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // OCTABER
  let OctExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "10") {
            OctExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let OctCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "10") {
            OctCommValArr.push(item3?.comm_value);
          }
        });
    });

  let OctRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "10") {
            OctRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let OctFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "10") {
            OctFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // NOVEMBER
  let NovExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "11") {
            NovExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let NovCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "11") {
            NovCommValArr.push(item3?.comm_value);
          }
        });
    });

  let NovRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "11") {
            NovRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let NovFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "11") {
            NovFactValueArr.push(item3?.factory_value);
          }
        });
    });
  // DECEMBER
  let DecExportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "12") {
            DecExportQtyArr.push(
              item3?.sales_qty ? item3?.sales_qty : item3?.export_qty
            );
          }
        });
    });

  let DecCommValArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "12") {
            DecCommValArr.push(item3?.comm_value);
          }
        });
    });

  let DecRDLValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "12") {
            DecRDLValueArr.push(item3?.rdl_value);
          }
        });
    });

  let DecFactValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      item2?.buyer_name &&
        item2?.data?.map((item3, i) => {
          if (item3.month === "12") {
            DecFactValueArr.push(item3?.factory_value);
          }
        });
    });

  // Grand Total
  let GrandExportQtyArr = [
    ...JanExportQtyArr,
    ...FebExportQtyArr,
    ...MarExportQtyArr,
    ...AprExportQtyArr,
    ...MayExportQtyArr,
    ...JunExportQtyArr,
    ...JulExportQtyArr,
    ...AugExportQtyArr,
    ...SepExportQtyArr,
    ...OctExportQtyArr,
    ...NovExportQtyArr,
    ...DecExportQtyArr,
  ];

  console.log("GrandExportQtyArr", GrandExportQtyArr);

  let GrandCommValArr = [
    ...JanCommValArr,
    ...FebCommValArr,
    ...MarCommValArr,
    ...AprCommValArr,
    ...MayCommValArr,
    ...JunCommValArr,
    ...JulCommValArr,
    ...AugCommValArr,
    ...SepCommValArr,
    ...OctCommValArr,
    ...NovCommValArr,
    ...DecCommValArr,
  ];

  let GrandRDLValueArr = [
    ...JanRDLValueArr,
    ...FebRDLValueArr,
    ...MarRDLValueArr,
    ...AprRDLValueArr,
    ...MayRDLValueArr,
    ...JunRDLValueArr,
    ...JulRDLValueArr,
    ...AugRDLValueArr,
    ...SepRDLValueArr,
    ...OctRDLValueArr,
    ...NovRDLValueArr,
    ...DecRDLValueArr,
  ];

  let GrandFactValueArr = [
    ...JanFactValueArr,
    ...FebFactValueArr,
    ...MarFactValueArr,
    ...AprFactValueArr,
    ...MayFactValueArr,
    ...JunFactValueArr,
    ...JulFactValueArr,
    ...AugFactValueArr,
    ...SepFactValueArr,
    ...OctFactValueArr,
    ...NovFactValueArr,
    ...DecFactValueArr,
  ];

  let sl_no = 0;

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap={true}>
        {/* Header Info */}
        <View>
          <View
            style={{
              width: "100%",
              marginBottom: "10px",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <View style={{ width: "100%", textAlign: "center" }}>
              <Text
                style={{ fontSize: 16, marginBottom: 5, fontWeight: "bold" }}
              >
                EXPORT REPORT
              </Text>
              <Text style={{ fontSize: 9 }}>Renaissance Designs Ltd.</Text>
              <Text style={{ fontSize: 9, paddingTop: 4, paddingBottom: 4 }}>
                House 14,Road 2,Sector 3,Uttara,Dhaka,Bangladesh
              </Text>
            </View>
          </View>

          <View
            style={{
              marginTop: "30px",
              paddingLeft: 20,
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: "550x",
                textAlign: "center",
                border: "1px solid black",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    padding: 5,
                    width: "120px",
                    textAlign: "center",
                    fontWeight: "bold",
                    borderRight: "1px solid black",
                    borderBottom: 0,
                  }}
                >
                  Report Type
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 5,
                    width: "330px",
                    textAlign: "center",
                    fontWeight: "bold",
                    borderBottom: 0,
                  }}
                >
                  Export Report
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    padding: 5,
                    width: "120px",
                    textAlign: "center",
                    fontWeight: "bold",
                    borderRight: "1px solid black",
                    borderBottom: 0,
                  }}
                >
                  Sales Year
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 5,
                    width: "330px",
                    textAlign: "center",
                    fontWeight: "bold",
                    borderBottom: 0,
                  }}
                >
                  {tableBodyData && tableBodyData.length
                    ? tableBodyData[0].year
                    : null}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 5,
                    width: "120px",
                    fontWeight: "bold",
                    textAlign: "center",
                    borderRight: "1px solid black",
                    borderBottom: 0,
                  }}
                >
                  Sales Date{" "}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    padding: 5,
                    width: "330px",
                    textAlign: "center",
                    fontWeight: "bold",
                    borderBottom: 0,
                  }}
                >
                  January To December
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/*1st TABLE  */}
        <View style={{ marginTop: "30px" }}>
          <View fixed>
            {/*1st Table First Header */}
            <View
              style={{
                border: "1px solid black",
                borderBottom: 0,
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  width: `32.1%`,
                  textAlign: "center",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                Month
              </Text>

              <Text
                style={{
                  padding: 5,
                  width: "34%",
                  fontSize: "10px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                JAN
              </Text>

              <Text
                style={{
                  padding: 5,
                  width: "34%",
                  fontSize: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                FEB
              </Text>
            </View>

            {/*1st Table Second Header */}
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  textAlign: "center",
                  alignItems: "center",
                  width: "2%",
                  padding: 5,
                }}
              >
                SL
              </Text>

              {state && state?.buyer ? (
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    width: "30%",
                    borderLeft: "1px solid black",
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  Buyer
                </Text>
              ) : null}

              {state && state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}
              {state && state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}
              {state && state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state && state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state && state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}

              {state && state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}
              {state && state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}
              {state && state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state && state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state && state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${34 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}
            </View>
          </View>
          {/* Table Body Part Start */}
          <View>
            {tableBodyData &&
              tableBodyData.length &&
              tableBodyData.map((item, index) => {
                if (item?.buyer_name) {
                  sl_no++;
                }
                return item?.buyer_name ? (
                  <View
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    key={index}
                    wrap={false}
                    break={index > 6}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      {/* parameter */}
                      <View style={styles.textContainer}>
                        <Text style={styles.textElement}>{sl_no}</Text>

                        {state?.buyer ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "30%",
                              borderLeft: "1px solid black",
                              height: "auto",
                            }}
                          >
                            <Text style={styles.parameterText}>
                              {item?.buyer_name}
                            </Text>
                          </View>
                        ) : null}

                        {/* result */}
                        {state?.quantity ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data && item?.data.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "1") {
                                      return `${
                                        item2?.sales_qty
                                          ? item2?.sales_qty
                                          : item2?.export_qty
                                      }`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}
                        {state?.quantity_percentage ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data &&
                              item?.data.length &&
                              TotalQty &&
                              TotalQty.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "1") {
                                      return `${
                                        item2?.sales_qty
                                          ? Number(
                                              (item2?.sales_qty * 100) /
                                                TotalQty[index]
                                            ).toFixed(2)
                                          : Number(
                                              (item2?.export_qty * 100) /
                                                TotalQty[index]
                                            ).toFixed(2)
                                      } %`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}
                        {state?.rdl_value ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data && item?.data.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "1") {
                                      return `$ ${
                                        item2?.rdl_value
                                          ? Number(item2?.rdl_value).toFixed(2)
                                          : "0.00"
                                      }`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}
                        {state?.factory_value ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data && item?.data.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "1") {
                                      return `$ ${
                                        item2?.factory_value
                                          ? Number(
                                              item2?.factory_value
                                            ).toFixed(2)
                                          : "0.00"
                                      }`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}
                        {state?.commission_value ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data && item?.data.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "1") {
                                      return `$ ${
                                        item2?.comm_value
                                          ? Number(item2?.comm_value).toFixed(2)
                                          : "0.00"
                                      }`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}

                        {state?.quantity ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data && item?.data.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "2") {
                                      return `${
                                        item2?.sales_qty
                                          ? item2?.sales_qty
                                          : item2?.export_qty
                                      }`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}
                        {state?.quantity_percentage ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data &&
                              item?.data.length &&
                              TotalQty &&
                              TotalQty.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "2") {
                                      return `${
                                        item2?.sales_qty
                                          ? Number(
                                              (item2?.sales_qty * 100) /
                                                TotalQty[index]
                                            ).toFixed(2)
                                          : Number(
                                              (item2?.export_qty * 100) /
                                                TotalQty[index]
                                            ).toFixed(2)
                                      } %`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}
                        {state?.rdl_value ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data && item?.data.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "2") {
                                      return `$ ${
                                        item2?.rdl_value
                                          ? Number(item2?.rdl_value).toFixed(2)
                                          : "0.00"
                                      }`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}
                        {state?.factory_value ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data && item?.data.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "2") {
                                      return `$ ${
                                        item2?.factory_value
                                          ? Number(
                                              item2?.factory_value
                                            ).toFixed(2)
                                          : "0.00"
                                      }`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}
                        {state?.commission_value ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: `${34 / countResult}%`,
                              height: "auto",
                              borderLeft: "1px solid black",
                            }}
                          >
                            <Text style={styles.resultContainerText}>
                              {item?.data && item?.data.length
                                ? item?.data?.map((item2, i) => {
                                    if (item2.month === "2") {
                                      return `$ ${
                                        item2?.comm_value
                                          ? Number(item2?.comm_value).toFixed(2)
                                          : "0.00"
                                      }`;
                                    }
                                  })
                                : null}
                            </Text>
                          </View>
                        ) : null}
                      </View>
                    </View>
                  </View>
                ) : null;
              })}
            {/* factory total calculation */}
            <View
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "32%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={styles.totalContentText}>Total</Text>
              </View>
              {state?.quantity ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    {JanExportQtyArr?.reduce(
                      (totalQty, currentQty) => totalQty + Number(currentQty),
                      0
                    ) ?? 0}
                  </Text>
                </View>
              ) : null}
              {state?.quantity_percentage ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    {/* {item?.data?.[0]?.comm_per} */}
                  </Text>
                </View>
              ) : null}
              {state?.rdl_value ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    ${" "}
                    {JanRDLValueArr?.reduce(
                      (totalV, currentV) => totalV + Number(currentV),
                      0
                    ).toFixed(2) ?? 0}
                  </Text>
                </View>
              ) : null}
              {state?.factory_value ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    ${" "}
                    {JanFactValueArr?.reduce(
                      (totalV, currentV) => totalV + Number(currentV),
                      0
                    ).toFixed(2) ?? 0}
                  </Text>
                </View>
              ) : null}
              {state?.commission_value ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    ${" "}
                    {JanCommValArr?.reduce(
                      (totalV, currentV) => totalV + Number(currentV),
                      0
                    ).toFixed(2) ?? 0}
                  </Text>
                </View>
              ) : null}

              {state?.quantity ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    {FebExportQtyArr?.reduce(
                      (totalQty, currentQty) => totalQty + Number(currentQty),
                      0
                    ) ?? 0}
                  </Text>
                </View>
              ) : null}
              {state?.quantity_percentage ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    {/* {item?.data?.[1]?.comm_per} */}
                  </Text>
                </View>
              ) : null}

              {state?.rdl_value ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    ${" "}
                    {FebRDLValueArr?.reduce(
                      (totalV, currentV) => totalV + Number(currentV),
                      0
                    ).toFixed(2) ?? 0}
                  </Text>
                </View>
              ) : null}
              {state?.factory_value ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    ${" "}
                    {FebFactValueArr?.reduce(
                      (totalV, currentV) => totalV + Number(currentV),
                      0
                    ).toFixed(2) ?? 0}
                  </Text>
                </View>
              ) : null}
              {state?.commission_value ? (
                <View
                  style={{
                    width: `${34 / countResult}%`,
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text style={styles.totalContentText}>
                    ${" "}
                    {FebCommValArr?.reduce(
                      (totalV, currentV) => totalV + Number(currentV),
                      0
                    ).toFixed(2) ?? 0}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>

        {/*2nd Table */}
        <View break style={{ paddingTop: 110 }}>
          <View fixed>
            {/* first header */}
            <View
              style={{
                border: "1px solid black",
                borderBottom: 0,
                marginTop: 30,
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  width: "33.38%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                MAR
              </Text>

              <Text
                style={{
                  padding: 5,
                  width: `33.31%`,
                  fontSize: "10px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                APR
              </Text>

              <Text
                style={{
                  padding: 5,
                  width: `33.31%`,
                  fontSize: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                MAY
              </Text>
            </View>
            {/* second header */}
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}

              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}

              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}
            </View>
          </View>

          {/*Table Part */}
          {tableBodyData &&
            tableBodyData.length &&
            tableBodyData.map((item, index) => {
              return item?.buyer_name ? (
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                  key={index}
                  wrap={false}
                  break={index > 6}
                >
                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "3") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "3") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "3") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "3") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "3") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "4") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "4") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "4") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "4") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "4") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "5") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "5") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "5") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "5") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "5") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                </View>
              ) : null;
            })}
          <View
            wrap={false}
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                }}
              >
                <Text style={styles.totalContentText}>
                  {MarExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}
            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {MarRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {MarFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {MarCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {AprExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}

            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {AprRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {AprFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {AprCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {MayExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}

            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {MayRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {MayFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {MayCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        {/*3rd Table */}
        <View break style={{ paddingTop: 110 }}>
          <View fixed>
            {/* first header */}
            <View
              style={{
                border: "1px solid black",
                borderBottom: 0,
                marginTop: 30,
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  width: "33.38%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                JUN
              </Text>

              <Text
                style={{
                  padding: 5,
                  width: `33.31%`,
                  fontSize: "10px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                JUL
              </Text>

              <Text
                style={{
                  padding: 5,
                  width: `33.31%`,
                  fontSize: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                AUG
              </Text>
            </View>
            {/* second header */}
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}

              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}

              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}
            </View>
          </View>

          {/*Table Part */}
          {tableBodyData &&
            tableBodyData.length &&
            tableBodyData.map((item, index) => {
              return item?.buyer_name ? (
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                  key={index}
                  wrap={false}
                  break={index > 6}
                >
                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "6") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "6") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "6") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "6") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "6") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "7") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "7") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "7") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "7") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "7") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "8") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "8") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "8") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "8") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "8") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                </View>
              ) : null;
            })}
          <View
            wrap={false}
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                }}
              >
                <Text style={styles.totalContentText}>
                  {JunExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}
            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {JunRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {JunFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {JunCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {JulExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}

            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {JulRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {JulFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {JulCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {AugExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}

            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {AugRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {AugFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {AugCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        {/*4th Table */}
        <View break style={{ paddingTop: 110 }}>
          <View fixed>
            {/* first header */}
            <View
              style={{
                border: "1px solid black",
                borderBottom: 0,
                marginTop: 30,
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  width: "33.38%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                SEP
              </Text>

              <Text
                style={{
                  padding: 5,
                  width: `33.31%`,
                  fontSize: "10px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                OCT
              </Text>

              <Text
                style={{
                  padding: 5,
                  width: `33.31%`,
                  fontSize: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                NOV
              </Text>
            </View>
            {/* second header */}
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}

              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}

              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${33.33 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}
            </View>
          </View>

          {/*Table Part */}
          {tableBodyData &&
            tableBodyData.length &&
            tableBodyData.map((item, index) => {
              return item?.buyer_name ? (
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                  key={index}
                  wrap={false}
                  break={index > 6}
                >
                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "9") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "9") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "9") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "9") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "9") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "10") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "10") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "10") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "10") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "10") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "11") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "11") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "11") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "11") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        borderLeft: "1px solid black",
                        width: `${33.33 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "11") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                </View>
              ) : null;
            })}
          <View
            wrap={false}
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                }}
              >
                <Text style={styles.totalContentText}>
                  {SepExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}
            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {SepRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {SepFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {SepCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {OctExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}

            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {OctRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {OctFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {OctCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {NovExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}

            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {NovRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {NovFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${33.33 / countResult}%`,
                  height: "auto",
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {NovCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        {/*5th Table */}
        <View break style={{ paddingTop: 110 }}>
          <View fixed>
            {/* first header */}
            <View
              style={{
                border: "1px solid black",
                borderBottom: 0,
                marginTop: 30,
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  width: "50.1%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                DEC
              </Text>

              <Text
                style={{
                  padding: 5,
                  width: "50%",
                  fontSize: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                GRAND TOTAL
              </Text>
            </View>
            {/* second header */}
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}

              {state?.quantity ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}

              {state?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {state?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {state?.commission_value ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}
              {state?.quantity_percentage ? (
                <Text
                  style={{
                    padding: 5,
                    height: "auto",
                    textAlign: "center",
                    width: `${50 / countResult}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                ></Text>
              ) : null}
            </View>
          </View>
          {/*Table Part */}
          {tableBodyData &&
            tableBodyData.length &&
            tableBodyData.map((item, index) => {
              return item?.buyer_name ? (
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                  key={index}
                  wrap={false}
                  break={index > 6}
                >
                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "12") {
                                return `${
                                  item2?.sales_qty
                                    ? item2?.sales_qty
                                    : item2?.export_qty
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                        borderLeft: "1px solid black",
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data &&
                        item?.data.length &&
                        TotalQty &&
                        TotalQty.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "12") {
                                return `${
                                  item2?.sales_qty
                                    ? Number(
                                        (item2?.sales_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                    : Number(
                                        (item2?.export_qty * 100) /
                                          TotalQty[index]
                                      ).toFixed(2)
                                } %`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                        borderLeft: "1px solid black",
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "12") {
                                return `$ ${
                                  item2?.rdl_value
                                    ? Number(item2?.rdl_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                        borderLeft: "1px solid black",
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "12") {
                                return `$ ${
                                  item2?.factory_value
                                    ? Number(item2?.factory_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                        borderLeft: "1px solid black",
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data && item?.data.length
                          ? item?.data?.map((item2, i) => {
                              if (item2.month === "12") {
                                return `$ ${
                                  item2?.comm_value
                                    ? Number(item2?.comm_value).toFixed(2)
                                    : "0.00"
                                }`;
                              }
                            })
                          : null}
                      </Text>
                    </View>
                  ) : null}

                  {state?.quantity ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                        borderLeft: "1px solid black",
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {item?.data?.reduce(
                          (totalQty, currentQty) =>
                            totalQty +
                            Number(
                              currentQty?.sales_qty
                                ? currentQty?.sales_qty
                                : currentQty?.export_qty
                            ),
                          0
                        )}
                      </Text>
                    </View>
                  ) : null}
                  {state?.rdl_value ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                        borderLeft: "1px solid black",
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        ${" "}
                        {item?.data
                          ?.reduce(
                            (totalRDL, currentRDL) =>
                              totalRDL + Number(currentRDL?.rdl_value),
                            0
                          )
                          .toFixed(2)}
                      </Text>
                    </View>
                  ) : null}
                  {state?.factory_value ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                        borderLeft: "1px solid black",
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        ${" "}
                        {item?.data
                          ?.reduce(
                            (totalFactV, currentFactV) =>
                              totalFactV + Number(currentFactV?.factory_value),
                            0
                          )
                          .toFixed(2)}
                      </Text>
                    </View>
                  ) : null}
                  {state?.commission_value ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                        borderLeft: "1px solid black",
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        ${" "}
                        {item?.data
                          ?.reduce(
                            (totalCommV, currentCommV) =>
                              totalCommV + Number(currentCommV?.comm_value),
                            0
                          )
                          .toFixed(2)}
                      </Text>
                    </View>
                  ) : null}
                  {state?.quantity_percentage ? (
                    <View
                      style={{
                        display: "flex",
                        height: "auto",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `${50 / countResult}%`,
                        borderLeft: "1px solid black",
                      }}
                    >
                      <Text style={styles.resultContainerText}>
                        {/* {item?.data?.[0]?.comm_per} */}
                      </Text>
                    </View>
                  ) : null}
                </View>
              ) : null;
            })}
          <View
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              flexDirection: "row",
            }}
          >
            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                }}
              >
                <Text style={styles.totalContentText}>
                  {DecExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[11]?.comm_per} */}
                </Text>
              </View>
            ) : null}
            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {DecRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {DecFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {DecCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.quantity ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {GrandExportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.rdl_value ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {GrandRDLValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}

            {state?.factory_value ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {GrandFactValueArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.commission_value ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  ${" "}
                  {GrandCommValArr?.reduce(
                    (totalV, currentV) => totalV + Number(currentV),
                    0
                  ).toFixed(2) ?? 0}
                </Text>
              </View>
            ) : null}
            {state?.quantity_percentage ? (
              <View
                style={{
                  display: "flex",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${50 / countResult}%`,
                  borderLeft: "1px solid black",
                }}
              >
                <Text style={styles.totalContentText}>
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",

  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textElement: {
    fontSize: 9,
    textAlign: "center",
    width: "2%",
  },
  parameterContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    width: "7%",
    height: "auto",
    borderLeft: "1px solid black",
    justifyContent: "center",
    alignItems: "center",
  },
  parameterText: {
    padding: 3,
    fontSize: 9,
    textAlign: "center",
  },

  resultContainerText: {
    fontSize: 9,
    padding: 5,
    textAlign: "center",
  },
  resultFooterContainer: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "1px solid black",
    width: "10%",
  },
  totalContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    width: "6.66%",
    borderLeft: "1px solid black",
  },
  totalContentText: {
    fontSize: 10,
    textAlign: "center",
    fontWeight: "bold",
    padding: 5,
  },
  totalFooterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "1px solid black",
    width: "10%",
    height: "auto",
  },

  body: {
    paddingTop: 20,
    paddingBottom: 60,
    fontFamily: "Arial",
    paddingHorizontal: 20,
    minWidth: "150vw",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
